import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
} from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import './objective-popup.scss';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';

const data = [
  {
    id: 1,
    title: 'Website traffic',
    content: 'Attract the appropriate audience to your website.',
    cardTitle: 'WEB_TRAFFIC',
  },
  {
    id: 2,
    title: 'Lead',
    content: 'Drive conversions and generate leads by motivating customers to engage proactively.',
    cardTitle: 'LEAD',
  },
  {
    id: 3,
    cardTitle: ' APP_PROMOTION',
    title: 'App promotion',
    content: 'Boost app installs, engagement, and pre-registrations effectively.',
  },
  {
    id: 4,
    title: 'Awareness and consideration',
    cardTitle: 'AWARENESS',
    content:
      'Expand your brands reach and cultivate interest in your products or brand by merging brand objectives.',
  },
  {
    id: 5,
    cardTitle: 'NO_OBJECTIVE',
    title: 'Campaign with no specific objective',
    content: 'Select a campaign type initially, irrespective of your specific objective.',
  },
];

function ObjectivePopUp(props) {
  const { advertiserId, campaignId, open, handleClose, timezone } = props;
  const [selectedCard, setSelectedCard] = useState(null);
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleCardSelect = (id) => {
    setSelectedCard(id);
  };

  const handleContinue = () => {
    if (selectedCard) {
      const selectedTitle = data.find((item) => item.id === selectedCard)?.cardTitle;
      history.push(
        `/advertiser/line-item-create?advertiserId=${advertiserId}&campaignId=${campaignId}&timezone=${timezone}&selectedTitle=${encodeURIComponent(
          selectedTitle,
        )}`,
      );
      handleClose();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="edit-options-dialog-title"
      >
        <IconButton
          // edge="end"
          // color="inherit"
          onClick={handleClose}
          aria-label="close"
          style={{ position: 'absolute', top: 0, right: 0 }}
        >
          <CloseIcon style={{ color: '#d63' }} />
        </IconButton>

        <DialogTitle>What's your campaign objective</DialogTitle>

        <DialogContent>
          <h3>Choose your objective</h3>
          <br />
          <hr />
          <p>
            Select an Object to tailor your experience to the goals and setting that will work best
            for your campaign
          </p>
          <Grid container spacing={2}>
            {data.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card
                  className="Grid-card cursor-pointer"
                  style={{
                    border: selectedCard === item.id ? '2px solid #f8931d' : '2px solid #f0eeee',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  onClick={() => handleCardSelect(item.id)}
                >
                  <CardContent className="card-container" style={{ flex: 1 }}>
                    <Typography className="card-typography" style={{ fontWeight: 'bold' }}>
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      className="card-content"
                      style={{ marginTop: '20px' }}
                    >
                      {item.content}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!selectedCard}
            onClick={handleContinue}
            className="btn btn-primary d-flex ml-4"
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ObjectivePopUp;

import React, { useState, useEffect, useMemo } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import getSessionData from './../../service-utils/session-util';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { Loader } from '../../common';
import { getCurrencySymbol } from './../../common/utils';
import { Table } from '../../components';
import Services from '../../service-utils/services';
import { dateFormatMoment } from './../../components/format-date-moment';
import { Tabs, Tab } from '@material-ui/core';
import './account.scss';
import { AgencyGetBalance, AgencyLedgerDetails } from './../../service-utils/useApis';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { numberFormatter } from './../../common/utils';
import OrderDetails from './orders_table';
import PaymentIntegration from './payment-integration';
import logo from '../../assets/img/adzylogo.png';
import AccountDetails from './account-details';
import AdminCreditAmount from './admin-credit-amount';

export const AgencyAccount = () => {
  let { agencyId, roles, agencyCurrency } = getSessionData();
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [amountRefresh, setAmountrefresh] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const urlUserAgencyId = new URLSearchParams(location.search).get('agencyId');
  const agencyName = new URLSearchParams(location.search).get('agencyName');
  const userIsSuperadmin = roles.includes('SUPERADMIN');
  const userAgencyId = userIsSuperadmin ? urlUserAgencyId : agencyId;
  const paymentFlag = process.env.REACT_APP_PAYMENT_FLAG;

  const { data: agencyData = {} } = useQuery(
    ['AGENCY_DATA', agencyId],
    async () => {
      const response = await Services.getAgency(agencyId);
      const receivedData = response?.data?.agencyDto;
      return receivedData;
    },
    { enabled: !!agencyId },
  );

  const { data: agencyAccount = {}, refetch: refetchGetBalance } = AgencyGetBalance(
    agencyId,
    userAgencyId,
    { currency: agencyCurrency },
  );

  const {
    data: agencyLedgerDetails = {},
    isLoading: isLoadingAgencyLedgerDetails,
    refetch: refetchLedgerDetails,
  } = AgencyLedgerDetails(agencyId, userAgencyId, pageNumber, pageLimit, agencyCurrency);

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Billing
          </div>
        ),
      }),
    );
  }, [dispatch]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (roles.includes('SUPERADMIN')) {
      dispatch(
        setHeader({
          back: {
            text: 'Back',
          },
          header: (
            <div className="d-flex align-items-center justify-content-between position-relative">
              Account Info: {agencyName}
            </div>
          ),
        }),
      );
    } else if (roles.includes('ADMIN')) {
      dispatch(
        setHeader({
          header: (
            <div className="d-flex align-items-center justify-content-between position-relative">
              Account
            </div>
          ),
        }),
      );
    }
  }, [roles, dispatch]);

  useEffect(() => {
    refetchLedgerDetails();
  }, [pageNumber, pageLimit, refetchLedgerDetails, amountRefresh]);

  const tableData = useMemo(() => {
    return agencyLedgerDetails?.ledgerDetailsList || [];
  }, [agencyLedgerDetails]);

  const getTableHeader = [
    {
      dataField: 'recordDate',
      text: 'Date',
      formatter: (col, row) => dateFormatMoment(col, 'DD/MM/YYYY'),
    },
    { dataField: 'txnType', text: 'Transaction Type', formatter: (col, row) => col },
    { dataField: 'notes', text: 'Note', formatter: (col, row) => col },
    { dataField: 'txnRef', text: 'Reference', formatter: (col, row) => col },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (col, row) => `${getCurrencySymbol(col?.currency)} ${col?.amount.toFixed(2)}`,
    },
  ];

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };

  const handleAddBalance = () => {
    setIsOpen(true);
  };
  const handleBackButton = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={3} className=" ">
          <Paper className="p-0 mn-h-100 chart-container">
            <div className="p-3 Balance">
              <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                <Typography component="h5" variant="h6" className="pb-2 font30">
                  Balance
                </Typography>
                <Button
                  variant="contained"
                  type="submit"
                  className="btn btn-primary d-flex mb-2"
                  onClick={handleAddBalance}
                >
                  Add Balance
                </Button>
              </div>
              <Typography
                component="h3"
                variant="h3"
                className="pb-2 font50 cursor-pointer mt-2"
                title={`${getCurrencySymbol(agencyAccount?.spend?.currency)} ${
                  agencyAccount?.balancedAmount || isNaN(parseFloat(agencyAccount?.amount?.amount))
                    ? '0.00'
                    : parseFloat(agencyAccount?.amount?.amount).toFixed(2)
                }`}
              >
                {`${getCurrencySymbol(agencyAccount?.spend?.currency)} ${numberFormatter(
                  agencyAccount?.balancedAmount || isNaN(parseFloat(agencyAccount?.amount?.amount))
                    ? '0.00'
                    : parseFloat(agencyAccount?.amount?.amount),
                )}`}
              </Typography>
            </div>
          </Paper>
          <br />
          <Paper className="p-0 mn-h-100 chart-container">
            <div className="p-3 Balance">
              <Typography component="h5" variant="h6" className="pb-2 font30">
                Total Spend
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                className="pb-2 font50 cursor-pointer"
                title={`${getCurrencySymbol(agencyAccount?.spend?.currency)} ${
                  agencyAccount?.balancedAmount || isNaN(parseFloat(agencyAccount?.spend?.amount))
                    ? '0.00'
                    : parseFloat(agencyAccount?.spend?.amount).toFixed(2)
                }`}
              >
                {`${getCurrencySymbol(agencyAccount?.spend?.currency)} ${numberFormatter(
                  isNaN(parseFloat(agencyAccount?.spend?.amount))
                    ? '0.00'
                    : parseFloat(agencyAccount?.spend?.amount),
                )}`}
              </Typography>
            </div>
          </Paper>

          <br />
          <Paper className="p-0 mn-h-100 chart-container">
            <div className="p-3">
              <DialogContentText>
                For any payment-related queries or assistance, feel free to reach out to our support
                team at <a href="mailto:finance@adzylytics.com">finance@adzylytics.com OR</a>{' '}
                <a href="tel:+91 98901 10518">+91 98901 10518 .</a>
                <br /> Thank you for choosing our services. We value your business and look forward
                to serving you efficiently.
                <br />
                <span className="pb-2 text-primary"> Note : </span>
                Please ensure that your payment includes the applicable GST as per government
                regulations.
              </DialogContentText>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={9} className="d-grid ">
          {isOpen && (paymentFlag === 'true' || userIsSuperadmin) ? (
            <Paper className="p-3 mn-h-200 chart-container mb-4">
              {userIsSuperadmin ? (
                <AdminCreditAmount
                  handleBackButton={handleBackButton}
                  refetchLedgerDetails={refetchLedgerDetails}
                  refetchGetBalance={refetchGetBalance}
                  currency={agencyAccount?.amount?.currency}
                />
              ) : (
                <PaymentIntegration handleBackButton={handleBackButton} />
              )}
            </Paper>
          ) : (
            <Paper className="p-3 mn-h-200 chart-container">
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: 'var(--primary)',
                  },
                }}
              >
                <Tab
                  label="Order Details"
                  style={{
                    color: tabValue === 0 ? 'var(--primary)' : 'inherit',
                  }}
                />
                <Tab
                  label="Ledger Details"
                  style={{
                    color: tabValue === 1 ? 'var(--primary)' : 'inherit',
                  }}
                />
              </Tabs>

              {tabValue === 1 ? (
                <div>
                  {isLoadingAgencyLedgerDetails ? (
                    <Loader />
                  ) : (
                    <Table
                      keyField="id"
                      tableData={tableData}
                      tableHeader={getTableHeader}
                      isRemote={true}
                      onPageChangeFun={handleTableChange}
                      totalSize={agencyLedgerDetails?.totalElements}
                      activePage={pageNumber}
                      sizePerPage={pageLimit}
                      wrapperClasses="scroll-visible"
                      defaultSorted={[
                        {
                          dataField: 'firstName',
                          order: 'asc',
                        },
                      ]}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <OrderDetails amountRefresh={amountRefresh} setIsOpen={setIsOpen} />
                </div>
              )}
            </Paper>
          )}
        </Grid>
      </Grid>
      {paymentFlag === 'false' && !userIsSuperadmin && (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
          <Paper elevation={3} className="popcolor">
            <DialogTitle id="form-dialog-title" className="pb-0 dialogTitle">
              <div className="pb-0 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img src={logo} alt="Logo" className="logos" />
                </div>
                {agencyData?.agencyName?.length > 20 ? (
                  <span title={agencyData.agencyName} className="h3">
                    {agencyData.agencyName.slice(0, 20) + '...'}
                  </span>
                ) : (
                  <span className="h3">{agencyData?.agencyName}</span>
                )}
              </div>
            </DialogTitle>
          </Paper>
          <AccountDetails />
          <DialogActions className="p-3">
            <Button className="btn btn-secondary" size="medium" onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { Button, Loader } from '../../common';
import { TargetingOption } from './targeting-option';
import { isEmpty, isEqual } from 'lodash';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from 'react-query';
import Services from '../../service-utils/services';
import getSessionData from '../../service-utils/session-util';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { handleMsgOnForm } from '../../common/utils';
import { useHistory } from 'react-router-dom';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import OverlapLoader from '../../common/loader/OverlapLoader';
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} {...other}>
      {value === index && <Box className="h-100">{children}</Box>}
    </div>
  );
};

export const Targeting = ({ campaignId, lineItemId, expired, goToNextStep = () => {} }) => {
  const [value, setValue] = React.useState(0);
  const [payload, setPayload] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = React.useState(false);
  const [savedTargetingsData, setSavedTargetingsData] = useState({});
  let history = useHistory();
  const { agencyId } = getSessionData();
  const { advertiserId } = useAdvertiser();
  const queryStringParams = { agencyId };

  const { data: targetingMasterTypes = {}, isLoading: isTargetingMasterTypesLoading } = useQuery(
    ['TARGETING_MASTER'],
    async () => {
      const response = await Services.getTargetingMaster(queryString.stringify(queryStringParams));
      return response.data?.targetingDTOList;
    },
  );

  const {
    data: savedTargetings = {},
    isLoading: isSavedListLoading,
    refetch: refetchSavedTargettings,
  } = useQuery(
    ['SAVED_TARGETING', advertiserId, campaignId],
    async () => {
      const response = await Services.getSavedTargeting(
        advertiserId,
        campaignId,
        lineItemId,
        queryString.stringify(queryStringParams),
      );
      return response.data?.lineItemTargetingDTOMap;
    },
    { enabled: !!lineItemId },
  );

  useEffect(() => {
    let temp = {};

    if (!isEmpty(savedTargetings)) {
      temp = { ...temp, ...savedTargetings };
    }
    if (!isEmpty(payload)) {
      temp = { ...temp, ...payload };
    }
    if (isEmpty(savedTargetingsData)) {
      setSavedTargetingsData({ ...temp });
    } else if (!isEmpty(savedTargetingsData) && !isEqual(savedTargetingsData, temp)) {
      setSavedTargetingsData({ ...temp });
    }
  }, [payload, savedTargetings, savedTargetingsData]);

  const savedTargetingIds = useMemo(() => {
    return Object.keys(savedTargetingsData).filter(
      (i) =>
        !isEmpty(savedTargetingsData[parseInt(i)].include) ||
        !isEmpty(savedTargetingsData[parseInt(i)].exclude),
    );
  }, [savedTargetingsData]);

  const { mutate: handleSubmit, isLoading: isLoading } = useMutation(
    async () => {
      const selectedTargettings = Object.values(savedTargetingsData);

      const payloadData = selectedTargettings.map((item) => {
        const { masterTargetingId, exclude, include } = item;
        return {
          exclude: exclude || [],
          include: include || [],
          lineItemId: parseInt(lineItemId),
          masterTargetingId: masterTargetingId,
        };
      });
      //.filter((item) => !isEmpty(item.include) || !isEmpty(item.exclude));

      const response = await Services.updateTargeting(
        advertiserId,
        campaignId,
        lineItemId,
        queryString.stringify(queryStringParams),
        payloadData,
      );
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, () => {});
      },
      onSuccess: () => {
        refetchSavedTargettings();
        //goToNextStep();
        goToNextStep(lineItemId);
        enqueueSnackbar('Submitted successfully.', { variant: 'success' });
      },
    },
  );

  /*
  const handleReset = () =>{
    setSavedTargetingsData({})
  }

  const { mutate: handleReset } = useMutation(
    async () => {
      const { masterTargetingId } = payload;
      const payloadData = {
        lineItemId: parseInt(lineItemId),
        masterTargetingId: masterTargetingId,
      };
      const response = await Services.resetTargeting(
        advertiserId,campaignId, lineItemId,
        queryString.stringify( queryStringParams),

        payloadData,
      );
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err);
      },
      onSuccess: () => {
        refetchSavedTargettings();
        enqueueSnackbar('Resetted successfully.', { variant: 'success' });
      },
    },
  );
  */

  const handleChange = (event, newValue) => {
    //console.log('--------------',event, newValue)
    setValue(newValue);
  };

  const targetingTypes = useMemo(() => {
    if (!isEmpty(targetingMasterTypes)) {
      return targetingMasterTypes.map((item) => {
        return {
          ...item,
          name: item?.label,
          type: item?.type,
        };
      });
    } else {
      return [];
    }
  }, [targetingMasterTypes]);

  return (
    <>
      {isTargetingMasterTypesLoading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex LI-trageting-tab border-bottom">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              className="tabs"
            >
              {targetingTypes?.map((item, index) => {
                return savedTargetingIds.includes(item.id.toString()) ? (
                  <Tab
                    className="targetting-added border-bottom"
                    label={
                      <>
                        <span>{item.name}</span>
                        <CheckIcon />
                      </>
                    }
                    key={index}
                    data={item.endpoint}
                  />
                ) : (
                  <Tab
                    className="border-bottom"
                    label={item.name}
                    key={index}
                    data={item.endpoint}
                  />
                );
              })}
            </Tabs>
            {targetingTypes?.map((item, index) => (
              <TabPanel value={value} key={index} index={index} className="w-100 overflow-auto ">
                <TargetingOption
                  data={item}
                  savedTargetings={savedTargetingsData}
                  isSavedListLoading={isSavedListLoading}
                  setPayload={setPayload}
                />
              </TabPanel>
            ))}
          </div>
          <div className="mt-4 d-flex justify-content-end ">
            <Button
              variant="contained"
              className="btn btn-secondary  my-2 mr-3 float-right"
              onClick={history.goBack}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="btn btn-secondary  my-2  float-right"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                goToNextStep(lineItemId);
              }}
              disabled={expired}
            >
              Next
            </Button>
            <Button
              disabled={expired}
              variant="contained"
              type="submit"
              className="btn btn-primary my-2 ml-3 float-right "
              onClick={() => handleSubmit()}
            >
              Update And Next
            </Button>
          </div>

          {/*<Button
            disabled={expired}
            variant="contained"
            type="submit"
            className="btn btn-primary my-2 mr-3 float-right "
            onClick={() => handleReset()}
          >
            Clear All Selection
          </Button>*/}

          <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <DialogTitle id="form-dialog-title" className="pb-0">
              Selected Targeting
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {!isEmpty(savedTargetingsData)
                  ? Object.keys(savedTargetingsData)?.map((key) => {
                      return JSON.stringify(savedTargetingsData[key]);
                    })
                  : 'No Data'}
                {/*Object.keys(savedTargetingsData)?.map(key=>{
            return savedTargetingsData[key]?.map(item =>{
              return item
            })
          })*/}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="p-3">
              <Button className="btn btn-secondary" size="medium" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button className="btn btn-secondary" size="medium" onClick={() => handleSubmit()}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {isLoading && <OverlapLoader />}
    </>
  );
};

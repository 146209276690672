import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Box, Button, Typography } from '../common';
import Chip from '@material-ui/core/Chip';
import history from '../history';
export const LoginForm = ({ handleOnSubmit, errorText }) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
    }),
    onSubmit: handleOnSubmit,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label="Email"
          margin="normal"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
          variant="outlined"
        />
        <TextField
          error={Boolean(formik.touched.password && formik.errors.password)}
          fullWidth
          helperText={formik.touched.password && formik.errors.password}
          label="Password"
          margin="normal"
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="password"
          value={formik.values.password}
          variant="outlined"
        />
        {!!errorText && <Chip label={errorText} className="alert-danger w-100" />}
        <Box sx={{ py: 2, maxHeight: '200px', overflow: 'auto' }}>
          <Button
            color="primary"
            className="btn btn-primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Sign In
          </Button>
        </Box>
        <div style={{ textAlign: 'center' }}>
          <Typography color="textSecondary" className="h6" variant="body2">
            <Typography
              color="textSecondary"
              className="h6 cursor-pointer"
              variant="body2"
              onClick={() => history.push('/forgot-password')}
            >
              Forgot Password
            </Typography>
            <Typography color="textSecondary" className="h6 mt-3" variant="body2">
              Don&apos;t have an account?
            </Typography>
          </Typography>

          <Typography color="textSecondary" className="h6" variant="body2">
            <Button
              onClick={() => history.push('/register')}
              className="mt-4 font-weight-bold"
              variant="contained"
              style={{ backgroundColor: '#F6A21E', color: '#000000' }}
            >
              Register Now
            </Button>
          </Typography>
        </div>
      </form>
    </>
  );
};

import React, { useRef } from 'react';
import './invoice.scss';
import { getSessionData } from '../../service-utils';
import { useQuery } from 'react-query';
import Services from '../../service-utils/services';
import { useParams } from 'react-router-dom';
import { dateFormatMoment } from '../../components/format-date-moment';
import { getCurrencySymbol } from '../../common/utils';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Button, Loader } from '../../common';

function Invoice() {
  const { agencyId } = getSessionData();
  const { orderId } = useParams();
  const stampImageRef = useRef(null);
  const logoImageRef = useRef(null);

  const {
    data,
    refetch: refresh,
    isLoading: isLoadingOrderDetails,
  } = useQuery(
    ['INVOICE_DETAILS', agencyId, orderId],
    async () => {
      const response = await Services.agencygetInvoice(agencyId, orderId);
      return response.data.invoiceDTO || {};
    },
    {
      enabled: !!agencyId,
    },
  );

  const invoice = {
    invoicingCompany: {
      companyName: 'MONTEJ TECH LLP',
      addressLine1: '15, Venkatest Ashish Apt Vrundavan Colony',
      addressLine2: 'NASHIK, Maharashtra 422009 India',
      postcode: '54321',
      GSTIN: 'GSTIN: 27ABMFM8435A1Z7',
    },
  };

  const downloadInvoice = async () => {
    const input = document.getElementById('printarea');
    const images = input.getElementsByTagName('img');

    // Wait for the logo and "Paid Stamp" images to load
    await Promise.all([
      new Promise((resolve) => {
        if (logoImageRef.current && logoImageRef.current.complete) {
          resolve();
        } else {
          logoImageRef.current.onload = resolve;
        }
      }),
      new Promise((resolve) => {
        if (stampImageRef.current && stampImageRef.current.complete) {
          resolve();
        } else {
          stampImageRef.current.onload = resolve;
        }
      }),
    ]);

    // Capture the screenshot and generate the PDF
    html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 0.5);
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`invoice_${orderId}.pdf`);
    });
  };

  return (
    <div className=" mb-4">
      {isLoadingOrderDetails ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className="invoice-container">
          <div className="justify-content-end mb-2 print-button">
            <Button
              className="btn btn-primary btn-38 ml-3 "
              size="large"
              onClick={downloadInvoice}
              endIcon={<CloudDownloadIcon />}
              style={{ fontWeight: 'bold' }}
            >
              PDF
            </Button>
          </div>
          <div className="invoice-box myDivToPrint" id="printarea">
            <table cellPadding="0" cellSpacing="0">
              <tr className="top">
                <td colSpan="6">
                  <table>
                    <tr>
                      <td className="title">
                        <img
                          ref={logoImageRef}
                          src="https://content.adzylytics.com/email-template/images/adzy-logo.png"
                          style={{ width: '60%', maxWidth: 300, marginTop: '-10px' }}
                          alt="Logo"
                          onLoad={() => console.log(' logo img load')}
                        />
                      </td>
                      <td>
                        <h1>TAX INVOICE</h1>
                        <h1>{data?.invoiceNumber}</h1>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="6">
                  <table>
                    <tr>
                      <td>
                        <h3>{invoice.invoicingCompany.companyName}</h3>

                        <p>Registered Address</p>
                        <p>{invoice.invoicingCompany.addressLine1}</p>
                        <p>
                          {invoice.invoicingCompany.addressLine2}{' '}
                          {invoice.invoicingCompany.postcode}
                        </p>
                        <p>{invoice.invoicingCompany.GSTIN}</p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr className="bill-to">
                <td colSpan="6">
                  <table>
                    <tr>
                      <td>
                        <p className="font-weight-bold">Order Id: {data?.orderId}</p>
                        <p> Invoice Date: {dateFormatMoment(data?.createdDate, 'DD MMM YYYY')}</p>
                        <p> Due Date: {dateFormatMoment(data?.createdDate, 'DD MMM YYYY')}</p>
                      </td>

                      <td className="billtoinfo">
                        <p>Bill To</p>

                        <h3>{data?.agencyName}</h3>
                        <p className="long-address">{data?.billAddress1}</p>
                        <p className="long-address">{data?.billAddress2}</p>
                        <p className="long-address">{data?.billCity}</p>
                        <p className="long-address">
                          {data?.billState} {data?.country} {data?.billZip}
                        </p>
                        <p></p>
                        {data?.gstinNo && <p>GSTIN: {data?.gstinNo}</p>}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="heading">
                <td style={{ width: '25%' }}>Description</td>
                <td style={{ width: '20%' }}>HSN/SAC</td>
                <td style={{ width: '5%' }}></td>
                <td style={{ width: '20%' }}>GST (18%)</td>
                <td style={{ width: '30%' }}>Amount</td>
              </tr>

              <tr className="item">
                <td>
                  <p>Internet Advertising</p>
                </td>
                <td>
                  <p>998361/998365</p>
                </td>
                <td>
                  <p></p>
                </td>
                <td>
                  <p>
                    {getCurrencySymbol(data?.currency)}
                    {data?.gst}
                  </p>
                </td>
                <td>
                  <p>
                    {getCurrencySymbol(data?.currency)}
                    {data?.amount}
                  </p>
                </td>
              </tr>

              <tr className="total">
                <td colSpan="6">
                  <p>
                    {' '}
                    Sub Total: {getCurrencySymbol(data?.currency)}
                    {data?.amount}
                  </p>
                </td>
              </tr>

              <tr className="total">
                <td colSpan="6">
                  <p>
                    {' '}
                    GST (18%): {getCurrencySymbol(data?.currency)}
                    {data?.gst}
                  </p>
                </td>
              </tr>

              <tr className="total">
                <td colSpan="6">
                  <h3>
                    {' '}
                    Total: {getCurrencySymbol(data?.currency)}
                    {data?.totalAmount}
                  </h3>
                </td>
              </tr>
              <tr className="totalleft">
                <td colSpan="6">
                  <img
                    ref={stampImageRef}
                    src="https://png.pngtree.com/png-clipart/20230317/original/pngtree-paid-stamp-vector-illustration-png-image_8991938.png"
                    style={{ width: '20%', maxWidth: 150, marginTop: '-140px' }}
                    className="ml-4"
                    alt="Paid Stamp"
                    onLoad={() => console.log(' Stamp img load')}
                  />
                </td>
              </tr>
            </table>

            <hr />
            <div className="d-flex align-items-center justify-content-center mt-2">
              <p className="d-flex align-items-center justify-content-center">Contact</p>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <p>4th Floor, Elpro City Square Mall, Chinchwad Pune Maharashtra India 411033</p>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <p>
                <a href="mailto:finance@adzylytics.com">finance@adzylytics.com</a> |
                <a href="tel:+919890110518">+91 9890110518</a>
              </p>
            </div>
          </div>
          <div className="page-break"></div>
        </div>
      )}
    </div>
  );
}

export default Invoice;

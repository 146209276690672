import CookieUtil from '../../service-utils/cookie-util';

const auth = () => {
  const { setAppCookie } = CookieUtil();

  const setUserData = (user) => {
    setAppCookie('user', JSON.stringify(user));
  };

  const setTokenData = (tokenData) => {
    setAppCookie('access_token', tokenData?.access_token);
    setAppCookie('refresh_token', tokenData?.refresh_token);
    setAppCookie('agencyId', tokenData?.agencyId);
    setAppCookie('agencyCurrency', tokenData?.agencyCurrency);
    setAppCookie('roles', tokenData?.roles);
    setAppCookie('userFullName', tokenData?.userFullName);
    setAppCookie('userId', tokenData?.userId);
  };
  return {
    setUserData,
    setTokenData,
  };
};

export default auth;

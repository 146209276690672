import React, { useState } from 'react';
import { RegistrationForm } from '../components'; // Update the path accordingly
import { Grid } from '../common';
import './register.scss';
import logo from './../assets/img/adzy-full.png';
import loginBg from '../../src/assets/img/login_bg.jpg';
import { Link } from 'react-router-dom';
import Footer from '../hoc/layout/footer';
export const Register = () => {
  return (
    <div className="registration-page">
      <img src={loginBg} alt="" className="position-fixed w-100" />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="d-flex vh-100 background"
      >
        <Grid
          item
          xs={4}
          className="d-flex justify-content-center align-items-center h-100 bg-white temp"
        >
          <img src={logo} alt="logo" className="img-fluid mb-1" />
          <div className="">
            <RegistrationForm redirectFrom="agency" />
          </div>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

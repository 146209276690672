import React, { useCallback, useEffect, useState, useMemo, Fragment } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Card from '@material-ui/core/Card';
import { NewTextField, Typography, Button, Loader } from '../../common';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { isEmpty, uniqBy, orderBy } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import classnames from 'classnames';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import Services from '../../service-utils/services';
import getSessionData from './../../service-utils/session-util';
import IconButton from '@material-ui/core/IconButton';
import pluseIcon from '../../assets/icon/plusicon.png';
import minusIcon from '../../assets/icon/minusicon.png';
const calculateNewState = (oldState, newStateData) => {
  const newState = [...newStateData];
  let calculatedState = [];
  if (!isEmpty(oldState)) {
    oldState.forEach((os) => {
      if (!isEmpty(newState)) {
        newState.forEach((ns) => {
          if (os.id === ns.id) {
            calculatedState = [
              ...calculatedState,
              {
                ...os,
                children: orderBy(uniqBy([...os.children, ...ns.children], 'id'), [
                  'orderId',
                  'label',
                ]),
              },
            ];
          } else {
            calculatedState = orderBy(uniqBy([...calculatedState, os, ns], 'id'), [
              'orderId',
              'label',
            ]);
          }
        });
      }
    });
  } else {
    calculatedState = newState;
  }
  return orderBy(calculatedState, ['orderId', 'label']);
};

const handleDataTransfer = (
  mainList,
  { allSelectedIds = [], selectedObj = {} },
  setMainList,
  setTargetList,
) => {
  let tempMainList = [];
  let tempTargetList = [];

  if (!isEmpty(mainList)) {
    mainList.forEach((item) => {
      let mainListItem = {};
      let targetListItem = {};
      if (allSelectedIds.includes(item.id)) {
        targetListItem = { ...item, children: [] };
      }
      if (
        !allSelectedIds.includes(item.id) ||
        (allSelectedIds.includes(item.id) &&
          item.children?.length > selectedObj[item.id]?.children?.length)
      ) {
        mainListItem = { ...item, children: [] };
      }

      if (!isEmpty(item.children)) {
        item.children.forEach((itemChild) => {
          if (allSelectedIds.includes(itemChild.id)) {
            if (isEmpty(targetListItem.children)) {
              targetListItem = {
                ...targetListItem,
                children: [itemChild],
              };
            } else {
              targetListItem = {
                ...targetListItem,
                children: [...targetListItem.children, itemChild],
              };
            }
          } else {
            if (isEmpty(mainListItem.children)) {
              mainListItem = {
                ...mainListItem,
                children: [itemChild],
              };
            } else {
              mainListItem = {
                ...mainListItem,
                children: [...mainListItem.children, itemChild],
              };
            }
          }
        });
      }

      tempMainList = isEmpty(mainListItem) ? tempMainList : [...tempMainList, mainListItem];
      tempTargetList = isEmpty(targetListItem)
        ? tempTargetList
        : [...tempTargetList, targetListItem];
    });
  }

  setTargetList((oldState) => {
    return calculateNewState(oldState, tempTargetList);
  });
  setMainList(orderBy(tempMainList, ['orderId', 'label']));

  return orderBy(tempMainList, ['orderId', 'label']);
};

const CardWithSearch = ({ title = '', cardId = '', data = [], setSelectedItems = {} }) => {
  const [searchText, setSearchText] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [expandedCountries, setExpandedCountries] = useState([]);

  const handleToggleExpansion = (itemId) => {
    setExpandedCountries((prevExpanded) =>
      prevExpanded.includes(itemId)
        ? prevExpanded.filter((id) => id !== itemId)
        : [...prevExpanded, itemId],
    );
  };
  useEffect(() => {
    let filterVal = [];
    if (searchText) {
      data.forEach((item) => {
        if (item?.label?.toLowerCase().includes(searchText?.toLowerCase())) {
          filterVal = [...filterVal, item];
        } else if (!isEmpty(item.children)) {
          const filteredChilds = item.children.filter((childItem) =>
            childItem?.label?.toLowerCase().includes(searchText?.toLowerCase()),
          );
          if (filteredChilds.length > 0) {
            filterVal = [...filterVal, { ...item, children: filteredChilds }];
          }
        }
      });
      setFilteredData(filterVal);
    } else {
      setFilteredData(data);
    }
  }, [data, searchText]);
  const handleChange = (e, selectedItem) => {
    const newData = filteredData.map((item) => {
      if (item.id === selectedItem.id) {
        return {
          ...item,
          children: item?.children?.map((i) => ({
            ...i,
            isChecked: e.target.checked,
          })),
          isChecked: e.target.checked,
        };
      } else if (!isEmpty(item.children)) {
        let tempParentId;
        const child = item.children.map((childItem) => {
          if (childItem.id === selectedItem.id) {
            tempParentId = item.id;
            return { ...childItem, isChecked: e.target.checked };
          }
          return childItem;
        });
        const selectedChilds = child.filter((i) => i.isChecked);
        if (item.children.length === selectedChilds.length) {
          return {
            ...item,
            isChecked: e.target.checked,
            children: child,
          };
        } else if (item.id === tempParentId && selectedChilds.length === 0) {
          return {
            ...item,
            isChecked: e.target.checked,
            children: child,
          };
        } else if (item.id === tempParentId && selectedChilds?.length < item?.children?.length) {
          return {
            ...item,
            isChecked: false,
            children: child,
          };
        } else {
          return {
            ...item,
            children: child,
          };
        }
      }
      return item;
    });

    let checkedData = [];

    newData.forEach((item) => {
      if (item?.isChecked) {
        checkedData = [...checkedData, item];
      } else if (!isEmpty(item.children)) {
        const filteredChilds = item.children.filter((childItem) => childItem?.isChecked);
        if (filteredChilds.length > 0) {
          checkedData = [...checkedData, { ...item, children: filteredChilds }];
        }
      }
    });
    setSelectedItems((oldState) => ({ ...oldState, [cardId]: checkedData }));

    setFilteredData(newData);
  };

  return (
    <Card className="h-100">
      <div className="px-2 pb-2 shadow-sm">
        <Typography className="instructions" variant="subtitle1">
          {title}
        </Typography>
        <NewTextField
          fullWidth
          label="Search"
          margin="normal"
          name="search"
          onChange={(event) => setSearchText(event.target.value)}
          value={searchText}
          variant="outlined"
          className="m-0"
        />
      </div>
      <div className="px-2 overflow-auto include-exclude-list">
        {filteredData.map((item) => {
          const checkedChildrens = item?.children?.filter((i) => i.isChecked);

          return (
            <Fragment key={item.id}>
              <ListItem
                dense
                button
                className="pt-1 px-2"
                onClick={() => handleToggleExpansion(item.id)}
              >
                <div className="d-flex align-items-center">
                  {item.children && (
                    <IconButton
                      className="mr-1"
                      edge="end"
                      onClick={() => handleToggleExpansion(item.id)}
                    >
                      {expandedCountries.includes(item.id) ? (
                        <img
                          src={minusIcon}
                          className="h-60 w-60"
                          onClick={() => handleToggleExpansion(item.id)}
                        />
                      ) : (
                        <img
                          src={pluseIcon}
                          className="h-60 w-60"
                          onClick={() => handleToggleExpansion(item.id)}
                        />
                      )}
                    </IconButton>
                  )}

                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={item.isChecked}
                      disableRipple
                      onChange={(e) => handleChange(e, item)}
                      indeterminate={
                        checkedChildrens?.length > 0 &&
                        item?.children?.length > checkedChildrens.length
                      }
                    />
                  </ListItemIcon>

                  <Typography className="text-nowrap  text-ellipsis">{item.label}</Typography>
                </div>
              </ListItem>

              {expandedCountries.includes(item.id) && !isEmpty(item.children) && (
                <div className="" style={{ marginLeft: '75px' }}>
                  {item.children.map((childItem) => (
                    <ListItem key={childItem.id} dense button>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={childItem.isChecked}
                          disableRipple
                          onChange={(e) => handleChange(e, childItem)}
                        />
                      </ListItemIcon>
                      <Typography className="text-nowrap text-ellipsis">
                        {childItem.label}
                      </Typography>
                    </ListItem>
                  ))}
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </Card>
  );
};

const IncludeExclude = ({
  masterTargetingId,
  masterList,
  isLoading,
  setPayload,
  savedTargetings = {},
}) => {
  const masterData = useMemo(
    () =>
      orderBy(
        masterList.map((item) => ({
          ...item,
          isChecked: false,
          totalChildrens: item.children?.length,
          children: !isEmpty(item.children)
            ? orderBy(
                [
                  ...item.children.map((childItem) => ({
                    ...childItem,
                    isChecked: false,
                  })),
                ],
                ['orderId', 'label'],
              )
            : [],
        })),
        ['orderId', 'label'],
      ),
    [masterList],
  );

  const [selectedItems, setSelectedItems] = useState({});
  const [mainList, setMainList] = useState();
  const [includeList, setIncludeList] = useState([]);
  const [excludeList, setExcludeList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    setMainList(masterData);
    setData(masterData);
  }, [masterData]);

  const setData = useCallback(
    (mainList) => {
      let newMainList = mainList;
      if (!isEmpty(savedTargetings.include) && !isEmpty(mainList)) {
        let allSelectedIds = [...savedTargetings?.include];
        let selectedObj = {};
        mainList.forEach((item) => {
          if (savedTargetings?.include?.includes(item.id)) {
            selectedObj = {
              ...selectedObj,
              [item.id]: {
                ...item,
                isChecked: true,
                totalChildrens: item.children?.length,
                children: item.children?.map((ci) => ({ ...ci, isChecked: true })),
              },
            };
            allSelectedIds = [...allSelectedIds, ...item.children?.map((ci) => ci.id)];
          } else if (!isEmpty(item.children)) {
            item.children.forEach((CItem) => {
              if (savedTargetings?.include?.includes(CItem.id)) {
                selectedObj = {
                  ...selectedObj,
                  [item.id]: {
                    ...item,
                    isChecked: true,
                    totalChildrens: item.children?.length,
                    children: selectedObj[item.id]
                      ? [...selectedObj[item.id]?.children, CItem]
                      : [CItem],
                  },
                };
                allSelectedIds = [...allSelectedIds, item.id];
              }
            });
          }
        });
        newMainList = handleDataTransfer(
          mainList,
          { allSelectedIds, selectedObj },
          setMainList,
          setIncludeList,
        );
      }

      if (!isEmpty(savedTargetings.exclude) && !isEmpty(newMainList)) {
        let allSelectedIds = [...savedTargetings?.exclude];
        let selectedObj = {};
        newMainList.forEach((item) => {
          if (savedTargetings?.exclude?.includes(item.id)) {
            selectedObj = {
              ...selectedObj,
              [item.id]: {
                ...item,
                isChecked: true,
                totalChildrens: item.children?.length,
                children: item.children?.map((ci) => ({ ...ci, isChecked: true })),
              },
            };
            allSelectedIds = [...allSelectedIds, ...item.children?.map((ci) => ci.id)];
          } else if (!isEmpty(item.children)) {
            item.children.forEach((CItem) => {
              if (savedTargetings?.exclude?.includes(CItem.id)) {
                selectedObj = {
                  ...selectedObj,
                  [item.id]: {
                    ...item,
                    isChecked: true,
                    totalChildrens: item.children?.length,
                    children: selectedObj[item.id]
                      ? [...selectedObj[item.id]?.children, CItem]
                      : [CItem],
                  },
                };
                allSelectedIds = [...allSelectedIds, item.id];
              }
            });
          }
        });
        handleDataTransfer(
          newMainList,
          { allSelectedIds, selectedObj },
          setMainList,
          setExcludeList,
        );
      }
    },
    [savedTargetings],
  );

  const getSelectedIds = (selectedItems) => {
    let selectedObj = {};
    let allSelectedIds = [];
    selectedItems.forEach((i) => {
      const childrenIds = i.children.map((ic) => ic.id);
      allSelectedIds = [...allSelectedIds, i.id, ...childrenIds];
      selectedObj = {
        ...selectedObj,
        [i.id]: { ...i },
      };
    });
    return { allSelectedIds, selectedObj };
  };
  const handleInclude = useCallback(() => {
    handleDataTransfer(
      mainList,
      getSelectedIds(selectedItems['masterList']),
      setMainList,
      setIncludeList,
    );
    setSelectedItems('masterList', []);
    setIsUpdate(true);
  }, [selectedItems, mainList]);

  const handleExclude = useCallback(() => {
    handleDataTransfer(
      mainList,
      getSelectedIds(selectedItems['masterList']),
      setMainList,
      setExcludeList,
    );
    setSelectedItems('masterList', []);
    setIsUpdate(true);
  }, [selectedItems, mainList]);

  const handleRemoveInclude = useCallback(() => {
    handleDataTransfer(
      includeList,
      getSelectedIds(selectedItems['includeList']),
      setIncludeList,
      setMainList,
    );
    setSelectedItems('masterList', []);
    setIsUpdate(true);
  }, [selectedItems, includeList]);

  const handleRemoveExclude = useCallback(() => {
    handleDataTransfer(
      excludeList,
      getSelectedIds(selectedItems['excludeList']),
      setExcludeList,
      setMainList,
    );
    setSelectedItems('masterList', []);
    setIsUpdate(true);
  }, [selectedItems, excludeList]);

  const getIds = (list) => {
    let ids = [];
    list.forEach((item) => {
      if (item.totalChildrens === item.children?.length || isEmpty(item.children)) {
        ids = [...ids, item.id];
      } else if (!isEmpty(item.children)) {
        ids = [...ids, ...item.children?.map((ci) => ci.id)];
      }
    });
    return ids;
  };

  useEffect(() => {
    if (isUpdate) {
      setPayload((oldState) => {
        return {
          ...oldState,
          [masterTargetingId]: {
            masterTargetingId,
            exclude: getIds(excludeList),
            include: getIds(includeList),
          },
        };
      });
    }
  }, [masterTargetingId, includeList, excludeList, isUpdate]);

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : (
        <div className="d-flex mh-100 include-exclude-targeting">
          <div className="col-4 my-2">
            <CardWithSearch
              title="Master List"
              cardId="masterList"
              data={mainList}
              setSelectedItems={setSelectedItems}
            />
          </div>
          <div className="col-2 d-flex justify-content-center flex-column">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleInclude}
              className={classnames('mb-4 btn btn-primary', {
                disabled: isEmpty(selectedItems['masterList']),
              })}
            >
              Include
            </Button>
            <Button
              variant="contained"
              startIcon={<RemoveIcon />}
              onClick={handleExclude}
              className={classnames('mb-4 btn btn-primary', {
                disabled: isEmpty(selectedItems['masterList']),
              })}
            >
              Exclude
            </Button>
            <Button
              variant="contained"
              startIcon={<ArrowBackIosIcon />}
              onClick={handleRemoveInclude}
              className={classnames('mb-4 btn btn-primary', {
                disabled: isEmpty(selectedItems['includeList']),
              })}
            >
              Remove Included
            </Button>
            <Button
              variant="contained"
              startIcon={<ArrowBackIosIcon />}
              onClick={handleRemoveExclude}
              className={classnames('mb-4 btn btn-primary', {
                disabled: isEmpty(selectedItems['excludeList']),
              })}
            >
              Remove Excluded
            </Button>
          </div>
          <div className="col-3 my-2">
            <CardWithSearch
              title="Include List"
              cardId="includeList"
              data={includeList}
              setSelectedItems={setSelectedItems}
            />
          </div>
          <div className="col-3 my-2">
            <CardWithSearch
              title="Exclude List"
              cardId="excludeList"
              data={excludeList}
              setSelectedItems={setSelectedItems}
            />
          </div>
        </div>
      )}
    </>
  );
};

const SingleSelect = ({
  masterTargetingId,
  masterList,
  savedTargetings = {},
  setPayload,
  isLoading,
}) => {
  const [checked, setChecked] = useState();

  const data = useMemo(() => {
    const savedIds = savedTargetings?.include || [];
    if (!isEmpty(savedIds)) {
      setChecked(savedIds[0]);
    }
    return masterList?.map((item) => ({
      ...item,
      name: item.label,
    }));
  }, [masterList, savedTargetings]);

  const handleChange = (event) => {
    setChecked(event.target.value);
  };

  useEffect(() => {
    setPayload((oldState) => ({
      ...oldState,
      [masterTargetingId]: { masterTargetingId, exclude: [], include: [parseInt(checked)] },
    }));
  }, [checked]);

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : (
        <FormControl component="fieldset" className="singleselect-targeting m-2">
          <RadioGroup name="singleSelect" value={checked?.toString()} onChange={handleChange}>
            {data.map((item) => (
              <FormControlLabel
                value={item.id?.toString()}
                control={<Radio />}
                label={item.name}
                className="radio-btns"
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </>
  );
};

const MultiSelect = ({
  masterTargetingId,
  masterList,
  savedTargetings = {},
  setPayload,
  isLoading,
}) => {
  const [checked, setChecked] = useState({});
  const handleChange = (event, id) => {
    setChecked((state) => ({ ...state, [id]: event.target.checked }));
  };

  const data = useMemo(() => {
    const savedIds = savedTargetings?.include || [];
    return masterList?.map((item) => ({
      ...item,
      name: item.label,
      isChecked: savedIds?.includes(item.id),
    }));
  }, [masterList, savedTargetings]);

  useEffect(() => {
    if (!isEmpty(savedTargetings?.include)) {
      setChecked(savedTargetings?.include?.reduce((a, v) => ({ ...a, [v]: true }), {}));
    }
  }, [masterList, savedTargetings]);

  useEffect(() => {
    if (!isEmpty(checked)) {
      setPayload((oldState) => ({
        ...oldState,
        [masterTargetingId]: {
          masterTargetingId,
          exclude: [],
          include:
            Object.keys(checked)
              .filter((item) => checked[item])
              .map((item) => parseInt(item)) || [],
        },
      }));
    }
  }, [checked]);

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : (
        <FormControl required component="fieldset" className="multiselect-targeting m-2">
          <FormGroup>
            {data.map((item) => {
              return (
                <FormControlLabel
                  key={`${item.id}-${checked[item.id]}`}
                  control={
                    <Checkbox
                      checked={checked[item.id]}
                      onChange={(e) => handleChange(e, item.id)}
                      name={item.name}
                    />
                  }
                  label={item.name}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      )}
    </>
  );
};

export const TargetingOption = ({
  data,
  savedTargetings = {},
  isSavedListLoading = false,
  setPayload,
}) => {
  const { id, type = '', targeting = '', endpoint } = data;
  let { agencyId = '' } = getSessionData();
  const queryStringParams = {
    agencyId,
  };

  const { data: masterList = [], isLoading: isMasterLoading } = useQuery(
    ['TARGETING_LIST', targeting],
    async () => {
      const response = await Services.getTargetingList(
        endpoint,
        queryString.stringify(queryStringParams),
      );
      return response.data?.masterList;
    },
  );

  return (
    <>
      {type === 'SINGLE_SELECT' && (
        <SingleSelect
          masterTargetingId={id}
          masterList={masterList}
          savedTargetings={savedTargetings[id]}
          setPayload={setPayload}
          isLoading={isSavedListLoading || isMasterLoading}
        />
      )}

      {(type === 'MULTI_SELECT' || type === 'checkbox') && (
        <MultiSelect
          masterTargetingId={id}
          masterList={masterList}
          savedTargetings={savedTargetings[id]}
          setPayload={setPayload}
          isLoading={isSavedListLoading || isMasterLoading}
        />
      )}

      {type === 'INCLUDE_EXCLUDE' && (
        <IncludeExclude
          masterTargetingId={id}
          masterList={masterList}
          setPayload={setPayload}
          isLoading={isMasterLoading}
          savedTargetings={savedTargetings[id]}
        />
      )}
    </>
  );
};

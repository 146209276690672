import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, Button, Typography, NewSelect } from '../common';
import history from '../history';
import Services from '../service-utils/services';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { AGENCY_TYPE, AGENCY_CURRENCY } from '../libs/constants';
import { handleMsgOnForm } from '../common/utils';
import { isEmpty } from 'lodash';
import checkIcon from './../assets/icon/check_icon.png';

import '../views/register.scss';
import OverlapLoader from '../common/loader/OverlapLoader';
export const RegistrationForm = ({ redirectFrom }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  const [successStatus, setSuccessStatus] = useState(false);
  const { mutate: createAgency, isLoading: isLoadingCreateAgency } = useMutation(
    async (values) => {
      const {
        agencyName,
        agencyType,
        contactNo,
        contactEmail,
        contactName,
        gstNumber,
        country,
        message,
        agencyCurrency,
      } = values;
      const payload = {
        agencyName,
        agencyType: agencyType?.value,
        contactNo,
        contactName,
        contactEmail,
        gstNumber,
        country,
        message,
        status: 0,
        currency: agencyCurrency?.value || 'USD',
      };
      const response = await Services.agencyCreate(payload);
      return response.data;
    },

    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        enqueueSnackbar('Agency created successfully.', { variant: 'success' });
        setSuccessStatus(true);
        // history.push(`/login`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      agencyName: '',
      agencyCurrency: AGENCY_CURRENCY[0],
    },
    validationSchema: Yup.object({
      agencyName: Yup.string()
        .min(2, 'Must be more than 1 characters')
        .max(255, 'Must be less than 255 characters')
        .required('Company name is required.'),

      // contactNo: Yup.string().min(9, "Must be more than 9 characters")
      // .max(10, "Must be less than 10 characters")
      // .required('contact Number is required.'),
    }),
    error: {},
    onSubmit: () => createAgency(values),
  });

  const handleCancel = () => {
    if (redirectFrom === 'agency') {
      history.push(`/agency`);
    } else {
      history.push(`/login`);
    }
  };

  return (
    <div className="registration-page">
      <Paper className="pt-3 p-4 mn-h-200">
        {!successStatus ? (
          <>
            <div className="justify-content-center d-flex">
              <p style={{ color: 'var(--primary)' }}>
                You're just a minute away from launching your campaign!
              </p>
            </div>

            <div className="d-flex justify-content-center mb-3">
              <Typography variant="h5">
                Create your <span style={{ color: 'var(--primary)' }}>adzylytics</span> account
              </Typography>
            </div>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} className="py-0">
                  <NewTextField
                    required
                    fullWidth
                    helperText={touched.agencyName && errors.agencyName}
                    error={Boolean(touched.agencyName && errors.agencyName)}
                    label="Company Name"
                    margin="normal"
                    name="agencyName"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('agencyName', event.target.value)}
                    value={values.agencyName}
                    variant="outlined"
                  />
                  {serverErrors.agencyName && (
                    <p className="text-danger mt-n2">{serverErrors.agencyName}</p>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} className="py-0">
                  <NewSelect
                    required
                    options={AGENCY_TYPE}
                    value={values.agencyType}
                    onChange={(obj) => setFieldValue('agencyType', obj)}
                    placeholder={`Company Type`}
                    className="py-2"
                  />
                  {serverErrors.agencyType && (
                    <p className="text-danger mt-n2">{serverErrors.agencyType}</p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} className="py-0">
                  <NewSelect
                    defaultValue={AGENCY_CURRENCY[0]}
                    options={AGENCY_CURRENCY}
                    value={values.agencyCurrency}
                    onChange={(obj) => setFieldValue('agencyCurrency', obj)}
                    placeholder={`Billing Currency`}
                    className="py-2"
                  />
                  {serverErrors.agencyCurrency && (
                    <p className="text-danger mt-n2">{serverErrors.agencyCurrency}</p>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} className="py-0">
                  <NewTextField
                    fullWidth
                    required
                    helperText={touched.contactName && errors.contactName}
                    error={Boolean(touched.contactName && errors.contactName)}
                    label="Contact Name"
                    margin="normal"
                    name="contactName"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('contactName', event.target.value)}
                    value={values.contactName}
                    variant="outlined"
                  />
                  {serverErrors.contactName && (
                    <p className="text-danger mt-n2">{serverErrors.contactName}</p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} className="py-0">
                  <NewTextField
                    required
                    fullWidth
                    helperText={touched.contactNo && errors.contactNo}
                    error={Boolean(touched.contactNo && errors.contactNo)}
                    label="Contact Number"
                    margin="normal"
                    name="contactNo"
                    // type="number"
                    // pattern="[0-9]*"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('contactNo', event.target.value)}
                    value={values.contactNo}
                    variant="outlined"
                  />
                  {serverErrors.contactNo && (
                    <p className="text-danger mt-n2">{serverErrors.contactNo}</p>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} className="py-0">
                  <NewTextField
                    fullWidth
                    required
                    helperText={touched.contactEmail && errors.contactEmail}
                    error={Boolean(touched.contactEmail && errors.contactEmail)}
                    label="Business E-mail"
                    margin="normal"
                    type="email"
                    pattern="[ /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i ]*"
                    name="contactEmail"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('contactEmail', event.target.value)}
                    value={values.contactEmail}
                    variant="outlined"
                  />
                  {serverErrors.contactEmail && (
                    <p className="text-danger mt-n2">{serverErrors.contactEmail}</p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} className="py-0">
                  <NewTextField
                    fullWidth
                    helperText={touched.gstNumber && errors.gstNumber}
                    error={Boolean(touched.gstNumber && errors.gstNumber)}
                    label="GST"
                    margin="normal"
                    name="gstNumber"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('gstNumber', event.target.value)}
                    value={values.gstNumber}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} className="py-0">
                  <NewTextField
                    fullWidth
                    helperText={touched.country && errors.country}
                    error={Boolean(touched.country && errors.country)}
                    label="Country"
                    margin="normal"
                    name="country"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('country', event.target.value)}
                    value={values.country}
                    variant="outlined"
                  />
                  {serverErrors.country && (
                    <p className="text-danger mt-n2">{serverErrors.country}</p>
                  )}
                </Grid>
              </Grid>

              {/* <Grid item xs={12} className="py-0">
            <NewTextField
              fullWidth
              label="Message"
              margin="normal"
              name="message"
              onBlur={handleBlur}
              onChange={(event) => setFieldValue('message', event.target.value)}
              value={values.message}
              variant="outlined"
              multiline
              rows={2}
              placeholder={'Message'}
            />
          </Grid> */}

              <div className="mt-4 d-flex justify-content-end ">
                <Button
                  variant="contained"
                  className="btn btn-secondary d-flex"
                  type="button"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="btn btn-primary d-flex ml-4"
                  type="submit"
                  disabled={isLoadingCreateAgency || !values.agencyName}
                >
                  {isLoadingCreateAgency ? 'Creating' : 'Create'}
                </Button>
              </div>
            </form>
          </>
        ) : (
          <div className="justify-content-center">
            <div className="d-flex justify-content-center mb-">
              <Typography variant="h5">
                Your <span style={{ color: 'var(--primary)' }}>adzylytics</span> account has been
                created successfully.
              </Typography>
            </div>
            <div className="sm d-flex justify-content-center align-items-center mt-3">
              <img src={checkIcon} style={{ width: '50px' }} alt="" />
            </div>
            <div className="d-flex justify-content-center mb-3 mt-4">
              <Typography component="p">Please check your email for your login details.</Typography>
            </div>
            <div className="mt-4 d-flex justify-content-center align-items-center ">
              <Button
                variant="contained"
                className="btn btn-primary d-flex"
                type="buttont"
                onClick={() => history.push('/login')}
              >
                login
              </Button>
            </div>
          </div>
        )}
      </Paper>
      {isLoadingCreateAgency && <OverlapLoader />}
    </div>
  );
};

import { httpService } from './';

const Services = {
  userLogin: (payload) => httpService(`public/auth/signin`, 'post', payload, 'um-api/v1'),
  userLogout: (query) => httpService(`public/auth/signout?${query}`, 'post', null, 'um-api/v1'),
  updatePassword: (userId, query, payload) =>
    httpService(`user/${userId}/updatePassword?${query}`, 'put', payload, 'um-api/v1'),

  advertisersGetAll: (query) => httpService(`advertisers?${query}`, 'get', '', 'dsp-api/v1'),
  advertisersSearch: (query) =>
    httpService(`advertisers/search?${query}`, 'get', null, 'dsp-api/v1'),
  advertiserGetDetails: (id, agencyId) =>
    httpService(`advertisers/${id}?agencyId=${agencyId}`, 'get', '', 'dsp-api/v1'),
  advertisersCreate: (agencyId, payload) =>
    httpService(`advertisers?agencyId=${agencyId}`, 'post', payload, 'dsp-api/v1'),
  advertisersUpdate: (id, agencyId, payload) =>
    httpService(`advertisers/${id}?agencyId=${agencyId}`, 'put', payload, 'dsp-api/v1'),
  advertiserDelete: (id, query) =>
    httpService(`advertisers/${id}?${query}`, 'delete', '', 'dsp-api/v1'),

  campaignGetAll: () => httpService(`campaign`, 'get', '', 'v1'),
  campaignSearch: (advertiserId, query) =>
    httpService(`advertiser/${advertiserId}/campaign?${query}`, 'get', null, 'dsp-api/v1'),

  campaignGetDetails: (id, advertiserId, query) =>
    httpService(`advertiser/${advertiserId}/campaign/${id}?${query}`, 'get', '', 'dsp-api/v1'),

  campaignCreate: (advertiserId, query, payload) =>
    httpService(`advertiser/${advertiserId}/campaign?${query}`, 'post', payload, 'dsp-api/v1'),

  campaignClone: (advertiserId, id, query, payload) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${id}/clone?${query}`,
      'post',
      payload,
      'dsp-api/v1',
    ),

  campaignUpdate: (advertiserId, id, query, payload) =>
    httpService(`advertiser/${advertiserId}/campaign/${id}?${query}`, 'put', payload, 'dsp-api/v1'),

  campaignDelete: (advertiserId, id, query) =>
    httpService(`advertiser/${advertiserId}/campaign/${id}?${query}`, 'delete', '', 'dsp-api/v1'),

  supplyGetContry: (query) =>
    httpService(`master/masterCountryStateCode?${query}`, 'get', '', 'dsp-api/v1'),

  conversionSearch: (query) => httpService(`conversion/search?${query}`, 'get', null, 'dsp-api/v1'),
  getConversion: (conversionId, query) =>
    httpService(`conversion/${conversionId}?${query}`, 'get', null, 'dsp-api/v1'),
  conversionCreate: (query, payload) =>
    httpService(`conversion/generateCode?${query}`, 'post', payload, 'dsp-api/v1'),

  //clusterGetAll: () => httpService(`placement/cluster`, 'get','' ,'v1'),
  clusterSearch: (advertiserId, query) =>
    httpService(
      `placement/advertiser/${advertiserId}/cluster/search?${query}`,
      'get',
      null,
      'dsp-api/v1',
    ),
  clusterGetDetails: (advertiserId, id, query) =>
    httpService(
      `placement/advertiser/${advertiserId}/cluster/${id}?${query}`,
      'get',
      '',
      'dsp-api/v1',
    ),
  clusterCreate: (advertiserId, query, payload) =>
    httpService(
      `placement/advertiser/${advertiserId}/cluster?${query}`,
      'post',
      payload,
      'dsp-api/v1',
    ),
  clusterUpdate: (advertiserId, id, query, payload) =>
    httpService(
      `placement/advertiser/${advertiserId}/cluster/${id}?${query}`,
      'put',
      payload,
      'dsp-api/v1',
    ),

  saveKeywords: (advertiserId, clusterId, query, payload) =>
    httpService(
      `placement/advertiser/${advertiserId}/cluster/${clusterId}/saveKeywords?${query}`,
      'post',
      payload,
      'dsp-api/v1',
    ),

  clusterDelete: (advertiserId, id, query) =>
    httpService(
      `placement/advertiser/${advertiserId}/cluster/${id}?${query}`,
      'delete',
      '',
      'dsp-api/v1',
    ),

  placementSearch: (query) =>
    httpService(`placement/advertiser/search?${query}`, 'get', null, 'dsp-api/v1'),

  addPlacemetsToCluster: (advertiserId, id, query, payload) =>
    httpService(
      `placement/advertiser/${advertiserId}/cluster/${id}/mapping?${query}`,
      'put',
      payload,
      'dsp-api/v1',
    ),
  removePlacemetsFromCluster: (advertiserId, id, query, payload) =>
    httpService(
      `placement/advertiser/${advertiserId}/cluster/${id}/mapping?${query}`,
      'delete',
      payload,
      'dsp-api/v1',
    ),
  lineItemClone: (advertiserId, campaignId, lineItemId, query, payload) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${lineItemId}/clone?${query}`,
      'post',
      payload,
      'dsp-api/v1',
    ),
  lineItemSearch: (advertiserId, campaignId, query) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem?${query}`,
      'get',
      null,
      'dsp-api/v1',
    ),
  lineItemGetDetails: (advertiserId, campaignId, id, query) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${id}?${query}`,
      'get',
      '',
      'dsp-api/v1',
    ),
  fetchLiveLineItems: (query) =>
    httpService(`fetchLiveLineItems?${query}`, 'get', null, 'dsp-api/v1/internal'),

  getLeadReportData: (leadFormId, query) =>
    httpService(`leadForm/${leadFormId}/leadData?${query}`, 'get', null, 'dsp-api'),

  getCampaignLineItems: (advertiserId, leadFormId, query) =>
    httpService(
      `advertiser/${advertiserId}/campaign/lineitem/hierarchy/leadForm/${leadFormId}?${query}`,
      'get',
      null,
      'dsp-api/v1',
    ),
  lineItemCreate: (advertiserId, campaignId, query, payload) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem?${query}`,
      'post',
      payload,
      'dsp-api/v1',
    ),

  leadFormCreate: (advertiserId, query, payload) =>
    httpService(`advertiser/${advertiserId}?${query}`, 'post', payload, 'dsp-api/leadForm'),
  getLeadformsToLineItem: (advertiserId, campaignId, lineItemId, agencyId) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${lineItemId}/geLeadForm?agencyId=${agencyId}`,
      'get',
      '',
      'dsp-api/v1',
    ),

  addLeadformsToLineItem: (advertiserId, campaignId, lineItemId, agencyId, payload) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${lineItemId}/addLeadForm?agencyId=${agencyId}`,
      'put',
      payload,
      'dsp-api/v1',
    ),

  leadFormUpdate: (advertiserId, formId, query, payload) =>
    httpService(
      `advertiser/${advertiserId}/form/${formId}?${query}`,
      'put',
      payload,
      'dsp-api/leadForm',
    ),
  leadFormSearch: (advertiserId, query) =>
    httpService(`advertiser/${advertiserId}/search?${query}`, 'get', null, 'dsp-api/leadForm'),
  uploadImage: (advertiserId, formId, agencyId, type, payload) =>
    httpService(
      `advertiser/${advertiserId}/form/${formId}/${type}/upload?agencyId=${agencyId}`,
      'post',
      payload,
      'dsp-api/leadForm',
    ),

  getLandingPageFields: (advertiserId, campaignId, lineItemId, agencyId) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${lineItemId}?agencyId=${agencyId}`,
      'get',
      '',
      'dsp-api/public/leadForm',
    ),

  getLeadFormById: (advertiserId, formId, agencyId) =>
    httpService(
      `advertiser/${advertiserId}/form/${formId}?${agencyId}`,
      'get',
      '',
      'dsp-api/leadForm',
    ),
  lineItemUpdate: (advertiserId, campaignId, id, query, payload) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${id}?${query}`,
      'put',
      payload,
      'dsp-api/v1',
    ),
  lineItemDelete: (id, query) => httpService(`lineItem/${id}?${query}`, 'delete', '', 'dsp-api/v1'),

  creativeSearch: (advertiserId, query) =>
    httpService(`advertiser/${advertiserId}/creative/search?${query}`, 'get', null, 'dsp-api/v1'),

  creativeSearchByAdv: (advertiserId, query) =>
    httpService(`creative/advertiser/${advertiserId}?${query}`, 'get', null, 'dsp-api/v1'),

  creativeGetDetails: (advertiserId, id, query) =>
    httpService(`advertiser/${advertiserId}/creative/${id}?${query}`, 'get', '', 'dsp-api/v1'),

  creativeCreate: (advertiserId, query, payload) =>
    httpService(`advertiser/${advertiserId}/creative?${query}`, 'post', payload, 'dsp-api/v1'),

  creativeClone: (advertiserId, creativeId, query, payload) =>
    httpService(
      `advertiser/${advertiserId}/creative/${creativeId}/clone?${query}`,
      'post',
      payload,
      'dsp-api/v1',
    ),

  creativeUpdate: (advertiserId, id, query, payload) =>
    httpService(`advertiser/${advertiserId}/creative/${id}?${query}`, 'put', payload, 'dsp-api/v1'),

  creativeDelete: (advertiserId, id, query) =>
    httpService(`advertiser/${advertiserId}/creative/${id}?${query}`, 'delete', '', 'dsp-api/v1'),
  uploadFile: (advertiserId, creativeId, type, data, agencyId) =>
    httpService(
      `advertiser/${advertiserId}/creative/${creativeId}/${type}/upload?agencyId=${agencyId}`,
      'post',
      data,
      'dsp-api/v1',
    ),
  addCreativeToLineItem: (advertiserId, campaignId, lineItemId, agencyId, payload) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${lineItemId}/addCreative?agencyId=${agencyId}`,
      'put',
      payload,
      'dsp-api/v1',
    ),
  getCreativesForLineItem: (advertiserId, campaignId, lineItemId, agencyId) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${lineItemId}/getCreatives?agencyId=${agencyId}`,
      'get',
      '',
      'dsp-api/v1',
    ),

  iabCategories: (query) => httpService(`master/master_iab?${query}`, 'get', '', 'dsp-api/v1'),

  agencyEmailsSetting: (agencyId, payload) =>
    httpService(`agency/${agencyId}/settings/notification/`, 'post', payload, 'um-api/v1'),
  getEmailsSetting: (agencyId) =>
    httpService(`agency/${agencyId}/settings/notification`, 'get', '', 'um-api/v1'),
  deleteEmailSetting: (agencyId, emailId) =>
    httpService(`agency/${agencyId}/settings/notification/${emailId}`, 'put', '', 'um-api/v1'),

  agencyCreate: (payload) => httpService(`public/agency`, 'post', payload, 'um-api/v1'),
  forgotPass: (payload) => httpService(`public/forgotpassword`, 'post', payload, 'um-api/v1'),
  agencyCreateAdmin: (agencyId) =>
    httpService(`agency/${agencyId}/createAdmin`, 'post', null, 'um-api/v1'),
  getAllAencies: (query) => httpService(`agency?${query}`, 'get', '', 'um-api/v1'),
  agencyUpdate: (query, payload) => httpService(`agency/${query}`, 'put', payload, 'um-api/v1'),

  deleteAgencyLogo: (agencyId) =>
    httpService(`agency/${agencyId}/logo/remove`, 'put', '', 'um-api/v1'),
  agencyLogo: (agencyId, payload) =>
    httpService(`agency/${agencyId}/logo/upload`, 'put', payload, 'um-api/v1'),

  getAgency: (query) => httpService(`agency/${query}`, 'get', '', 'um-api/v1'),
  agencyStatusUpdate: (agencyId, status) =>
    httpService(`agency/${agencyId}/${status}`, 'patch', '', 'um-api/v1'),
  agencyDelete: (agencyId) => httpService(`agency/${agencyId}`, 'delete', '', 'um-api/v1'),

  createOrder: (agencyId, payload) =>
    httpService(`payment/pg/${agencyId}/createOrder`, 'post', payload, 'um-api/v1'),

  agencygetOrderList: (agencyId, query) =>
    httpService(`payment/${agencyId}/getOrders?${query}`, 'get', '', 'um-api/v1'),

  agencygetInvoice: (agencyId, OrderId) =>
    httpService(`payment/${agencyId}/order/${OrderId}/invoice`, 'get', '', 'um-api/v1'),

  paymentSuccess: (agencyId, payload) =>
    httpService(`payment/verifyPayment/${agencyId}`, 'post', payload, 'um-api/v1'),

  agencyGetBalance: (agencyId, userAgencyId, query) =>
    httpService(`payment/balance/${agencyId}/${userAgencyId}?${query}`, 'get', '', 'um-api/v1'),
  agencyCreditBalance: (agencyId, userAgencyId, data) =>
    httpService(
      `payment/${userAgencyId}/adminCredit?agencyId=${agencyId}`,
      'post',
      data,
      'um-api/v1',
    ),
  agencyLedgerDetails: (userAgencyId, payload) =>
    httpService(`payment/${userAgencyId}/txnEntry?${payload}`, 'get', '', 'um-api/v1'),

  getUserProfileDetails: (userId, agencyId) =>
    httpService(`user/${userId}?agencyId=${agencyId}`, 'get', '', 'um-api/profile'),
  userProfileUpdate: (id, query, payload) =>
    httpService(`user?${query}`, 'put', payload, 'um-api/profile'),
  getAgencyUsers: (queryParam) => httpService(`user/list?${queryParam}`, 'get', '', 'um-api/v1'),
  getUserDetails: (userId, agencyId) =>
    httpService(`user/${userId}?agencyId=${agencyId}`, 'get', '', 'um-api/v1'),
  userCreate: (query, payload) => httpService(`user?${query}`, 'post', payload, 'um-api/v1'),
  userUpdate: (id, query, payload) =>
    httpService(`user/${id}?${query}`, 'put', payload, 'um-api/v1'),
  userDelete: (userId, payload) =>
    httpService(`user/${userId}?${payload}`, 'delete', null, 'um-api/v1'),
  userStatusUpdate: (userId, status, agencyId) =>
    httpService(`user/${userId}/${status}?agencyId=${agencyId}`, 'patch', '', 'um-api/v1'),

  getTargetingMaster: (query) =>
    httpService(`advertiser/master_targeting?${query}`, 'get', '', 'dsp-api/v1'),
  getTargetingList: (endPoint, query) =>
    httpService(`master${endPoint}?${query}`, 'get', '', 'dsp-api/v1'),
  updateTargeting: (advertiserId, campaignId, lineItemId, query, payload) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${lineItemId}/targeting?${query}`,
      'post',
      payload,
      'dsp-api/v1',
    ),
  getSavedTargeting: (advertiserId, campaignId, lineItemId, query) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${lineItemId}/targeting?${query}`,
      'get',
      '',
      'dsp-api/v1',
    ),
  resetTargeting: (advertiserId, campaignId, lineItemId, query, payload) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${lineItemId}/targeting/${payload.masterTargetingId}?${query}`,
      'delete',
      null,
      'dsp-api/v1',
    ),

  addUpdatePinCodes: (advertiserId, campaignId, lineItemId, payload, agencyId) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${lineItemId}/targeting/pinCode?agencyId=${agencyId}`,
      'post',
      payload,
      'dsp-api/v1',
    ),
  getAdvTargetings: (advertiserId, campaignId, lineItemId, agencyId) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/lineItem/${lineItemId}/targeting/pinCode?agencyId=${agencyId}`,
      'get',
      '',
      'dsp-api/v1',
    ),
  getMasterLeadFormData: (advertiserId, query) =>
    httpService(
      `master/advertiser/${advertiserId}/masterLeadForm?${query}`,
      'get',
      '',
      'dsp-api/v1',
    ),
  getMasterBannerSizes: (query) =>
    httpService(`master/master_banner_size?${query}`, 'get', '', 'dsp-api/v1'),
  getMasterLanguages: (query) =>
    httpService(`master/master_language?${query}`, 'get', '', 'dsp-api/v1'),
  getMasterMimeTypes: (query) =>
    httpService(`master/master_mime_types?${query}`, 'get', '', 'dsp-api/v1'),
  getMasterCreativeAttr: (query) =>
    httpService(`master/master_creative_attribute?${query}`, 'get', '', 'dsp-api/v1'),
  getMasterTrafficType: (query) =>
    httpService(`master/master_traffic_type?${query}`, 'get', '', 'dsp-api/v1'),
  getMasterTimezone: (query) =>
    httpService(`master/master_timezone?${query}`, 'get', '', 'dsp-api/v1'),

  downloadCampaignReport: (id, query) =>
    httpService(`report/campaign/${id}?${query}`, 'get', '', 'report-api'),
  ///report-api/report/campaign/
  ///dsp-api/v1/master/master_timezone

  //getMasterVideoFormats: (query) => httpService(`master/master_video_format_types?${query}`, 'get','' ,'dsp-api/v1'),

  // supplyGetContry:(query) => httpService(`master/masterCountryStateCode?${query}`, 'get','' ,'dsp-api/v1'),
  getCarrierInventory: (countryIsoCode, query) =>
    httpService(`${countryIsoCode}/carrier?${query}`, 'get', '', 'report-api/inventory'),
  getDeviceOSInventory: (countryIsoCode, query) =>
    httpService(`${countryIsoCode}/deviceOS?${query}`, 'get', '', 'report-api/inventory'),
  getDeviceTypeInventory: (countryIsoCode, query) =>
    httpService(`${countryIsoCode}/deviceType?${query}`, 'get', '', 'report-api/inventory'),
  getStateInventory: (countryIsoCode, query) =>
    httpService(`${countryIsoCode}/state?${query}`, 'get', '', 'report-api/inventory'),
  getPlacementInventory: (countryIsoCode, query) =>
    httpService(`${countryIsoCode}/placement?${query}`, 'get', '', 'report-api/inventory'),

  getOverviewDetails: (query) =>
    httpService(`getOverviewDetails?${query}`, 'get', '', 'report-api/dashboard/agency'),
  getCampaignWiseImpressions: (query) =>
    httpService(`campaignWiseImpressions?${query}`, 'get', '', 'report-api/dashboard/agency'),
  getImpressionTrendLine: (query) =>
    httpService(`impressionTrendLine?${query}`, 'get', '', 'report-api/dashboard/agency'),
  stateWiseImpressions: (query) =>
    httpService(`stateWiseImpressions?${query}`, 'get', '', 'report-api/dashboard/agency'),
  deviceWiseImpressions: (query) =>
    httpService(`deviceWiseImpressions?${query}`, 'get', '', 'report-api/dashboard/agency'),
  // http://localhost:9090/dsp-api/v1/campaign/categorySummary?agencyId=1
  categoryImpression: (query) =>
    httpService(`categorySummary?${query}`, 'get', '', 'dsp-api/v1/advertiser'),

  trendLineByCampaign: (campaignId, query) =>
    httpService(`${campaignId}/trendline?${query}`, 'get', '', 'report-api/report/campaign'),
  lineItemWiseImpressions: (campaignId, query) =>
    httpService(
      `${campaignId}/lineItemWiseImpressions?${query}`,
      'get',
      '',
      'report-api/report/campaign',
    ),
  creativeWiseImpressions: (campaignId, query) =>
    httpService(
      `${campaignId}/creativeWiseImpressions?${query}`,
      'get',
      '',
      'report-api/report/campaign',
    ),
  getCampaignCreativeList: (campaignId, query) =>
    httpService(`${campaignId}/masters?${query}`, 'get', '', 'report-api/report/campaign'),
  ///report-api/report/campaign/{campaignId}/masters
  getCampaignPlacement: (campaignId, query) =>
    httpService(
      `${campaignId}/placementWiseImpressions?${query}`,
      'get',
      '',
      'report-api/report/campaign',
    ),
  getCampaignState: (campaignId, query) =>
    httpService(
      `${campaignId}/stateWiseImpressions?${query}`,
      'get',
      '',
      'report-api/report/campaign',
    ),
  getCampaignDeviceOS: (campaignId, query) =>
    httpService(
      `${campaignId}/deviceOSWiseImpressions?${query}`,
      'get',
      '',
      'report-api/report/campaign',
    ),
  getCampaignDeviceType: (campaignId, query) =>
    httpService(
      `${campaignId}/deviceTypeWiseImpressions?${query}`,
      'get',
      '',
      'report-api/report/campaign',
    ),
  // https://qa-api.adzylytics.com/dsp-api/v1/master/master_advertiser_campaign?agencyId=1
  // http://qa-app-adzylytics:80/dsp-api/v1/master/master_advertiser_campaign?agencyId=12"
  getmasterADCampaign: (advertiserId, query) =>
    httpService(`advertiser/${advertiserId}/campaignList?${query}`, 'get', '', 'dsp-api/v1'),
  getmasterADCampaignSummary: (campaignId, advertiserId, query) =>
    httpService(
      `advertiser/${advertiserId}/campaign/${campaignId}/summary/report?${query}`,
      'get',
      '',
      'dsp-api/v1',
    ),

  getCampaignByCategory: (query) =>
    httpService(`advertiser/categorySummaryByAgency?${query}`, 'get', '', 'dsp-api/v1'),

  getOverviewDetailsByAdv: (agnecyId, advertiserId) =>
    httpService(
      `agency/${agnecyId}/advertiser/${advertiserId}/getOverviewDetailsByAdvertiser`,
      'get',
      '',
      'report-api/dashboard',
    ),

  getImpressionTrendLineByAdv: (agnecyId, advertiserId, query) =>
    httpService(
      `agency/${agnecyId}/advertiser/${advertiserId}/getTrendLineByAdvertiser?${query}`,
      'get',
      '',
      'report-api/dashboard',
    ),

  getStateWiseImpressionByAdv: (agnecyId, advertiserId) =>
    httpService(
      `agency/${agnecyId}/advertiser/${advertiserId}/stateWiseImpressionsForAdvertiser`,
      'get',
      '',
      'report-api/dashboard',
    ),

  getCampaignWiseImpressionsByAdv: (agnecyId, advertiserId) =>
    httpService(
      `agency/${agnecyId}/advertiser/${advertiserId}/campaignWiseImpressionsForAdvertiser`,
      'get',
      '',
      'report-api/dashboard',
    ),

  getDeviceWiseImpressionsByAdv: (agnecyId, advertiserId) =>
    httpService(
      `agency/${agnecyId}/advertiser/${advertiserId}/deviceWiseImpressionsForAdvertiser`,
      'get',
      '',
      'report-api/dashboard',
    ),

  getCampaignByCategoryByAdv: (advertiserId, query) =>
    httpService(
      `advertiser/${advertiserId}/categorySummaryByAdvertiser?${query}`,
      'get',
      '',
      'dsp-api/v1',
    ),
};

export default Services;

import React, { useEffect, useState } from 'react';
import { LoginForm } from '../../components';
import { Grid } from '../../common';
import history from '../../history';
import { useDispatch } from 'react-redux';
import auth from '../../common/auth';
import './login.scss';
import logo from '../../assets/img/adzy-full.png';
import loginBg from '../../assets/img/login_bg.jpg';
import Services from '../../service-utils/services';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import Footer from '../../hoc/layout/footer';
import OverlapLoader from '../../common/loader/OverlapLoader';

export const Login = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [errorText, setErrorText] = useState();

  const { setTokenData, setUserData } = auth();

  useEffect(() => {
    setUserData('');
  }, [setUserData]);

  //const navigate = useNavigate()

  const { mutate: handleOnSubmit, isLoading: isLoginLoading } = useMutation(
    async (values) => {
      const { email, password } = values;
      const payload = {
        password,
        username: email,
      };
      const { data } = await Services.userLogin(payload);
      return data;
    },
    {
      onError: (err) => {
        let errorMsg = err?.response.data.reasonDesc || 'Error in user login.';
        enqueueSnackbar(errorMsg, { variant: 'error' });
        setErrorText(errorMsg);
      },
      onSuccess: (data) => {
        enqueueSnackbar('Login successful.', { variant: 'success' });
        setTokenData({
          access_token: data.token,
          refresh_token: data.refreshToken,
          agencyCurrency: data.agencyCurrency,
          agencyId: data.agencyId,
          roles: data.roles,
          userFullName: data.userFullName,
          userId: data.userId || data.id,
        });
        setUserData(data?.username);
        dispatch({ type: 'LOGIN_SUCCESS', payload: data });
        history.replace('/dashboard');
      },
    },
  );

  return (
    <div className="login-page">
      <img src={loginBg} alt="" className="position-fixed w-100" />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="d-flex vh-100 background"
      >
        {/*<Grid item xs={8} className="d-flex justify-content-center ">
          <div className="info-container ">
            <div className="logo-container">
              <img src={logo} alt="logo" className="img-fluid" />
            </div>
            <Typography variant="subtitle1" component="div" className="text-white">
            Adzylytics is the software platform that allows advertisers to manage data<br />
            exchange accounts and multiple ad exchange accounts through one interface.
            </Typography>
          </div>
  </Grid>*/}
        <Grid
          item
          xs={4}
          className="d-flex justify-content-center align-items-center h-100 bg-white temp"
        >
          <img src={logo} alt="logo" className="img-fluid" />
          <div className="p-2">
            <LoginForm handleOnSubmit={handleOnSubmit} errorText={errorText} />
          </div>
        </Grid>
      </Grid>
      <Footer />
      {isLoginLoading && <OverlapLoader />}
    </div>
  );
};

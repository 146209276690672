import React, { useEffect, useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, NewSelect, Button, Typography } from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { USER_STATUS, USER_ROLE } from '../../libs/constants';
import queryString from 'query-string';
import getSessionData from '../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import OverlapLoader from '../../common/loader/OverlapLoader';

export const UserCreate = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const { agencyId, roles } = getSessionData();
  const [serverErrors, setServerErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
        },
      }),
    );
  }, [dispatch]);

  const { data: userData = {} } = useQuery(
    ['USER_DATA', userId],
    async () => {
      const response = await Services.getUserDetails(userId, agencyId);
      return response?.data?.userDTO;
    },
    { enabled: !!userId && !!agencyId },
  );

  const { mutate: createUser, isLoading: isLoadingCreateUser } = useMutation(
    async (values) => {
      const { name, email, password, status, roleList } = values;
      let payload = {};

      if (userId) {
        payload = {
          name,
          enabled: status.value,
          roleList: [roleList?.value], //roleList.map(item => item.value),
        };
      } else {
        payload = {
          name,
          email,
          password,
          enabled: status.value,
          roleList: [roleList?.value], //roleList.map(item => item.value),
          agencyId: parseInt(agencyId),
        };
      }
      const query = queryString.stringify({ agencyId });
      const { data } = !!userId
        ? await Services.userUpdate(userId, query, payload)
        : await Services.userCreate(query, payload);
      return data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data) => {
        if (!!userId) {
          enqueueSnackbar('User updated successfully.', { variant: 'success' });
        } else {
          enqueueSnackbar('User created successfully.', { variant: 'success' });
        }
        history.push(`/user`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      email: '',
      name: '',
      password: '',
      rePassword: '',
      roleList: USER_ROLE[2],
      status: USER_STATUS[0],
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Must be more than 1 characters')
        .max(255, 'Must be less than 255 characters')
        .required('Name is required.'),
      email: Yup.string().email('Invalid email address').required('Email is required.'),
      password: !userId ? Yup.string().required('Password is required.') : Yup.string(),
      rePassword: Yup.string().when('password', {
        is: (val) => !userId || val,
        then: Yup.string()
          .required('Confirm Password is required.')
          .oneOf([Yup.ref('password')], 'Passwords must match.'),
        otherwise: Yup.string(),
      }),
    }),
    onSubmit: () => createUser(values),
  });

  useEffect(() => {
    if (!isEmpty(userData)) {
      const getStatus =
        USER_STATUS.filter((item) => item.value === Number(userData.enabled))[0] || USER_STATUS[0];
      userData?.name && setFieldValue('name', userData.name);
      userData?.email && setFieldValue('email', userData.email);
      !isEmpty(getStatus) && setFieldValue('status', getStatus);
      userData?.roleList &&
        setFieldValue(
          'roleList',
          USER_ROLE.filter((item) => item.value === userData.roleList[0])[0],
        );
      if (userData?.roleList && userData?.roleList[0] === 'SUPERADMIN') {
        setIsSuperAdmin(true);
      }
    }
  }, [userData, setFieldValue]);

  useEffect(() => {
    //console.log('data values',values)
  }, [values]);

  const USER_ROLE_OPTIONS = useMemo(() => {
    if (roles.includes('SUPERADMIN')) {
      return [...USER_ROLE, { label: 'Super Admin', value: 'SUPERADMIN' }];
    } else {
      return USER_ROLE;
    }
  }, [roles]);

  return (
    <div className="col-md-10 bg-white rounded shadow m-auto mb-4 p-0">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            {!!userId ? 'Edit User' : 'Create New User'}
          </Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className="py-0">
              <NewTextField
                info="Enter name of user"
                disabled={isSuperAdmin}
                required
                fullWidth
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('name', event.target.value)}
                value={values.name}
                variant="outlined"
              />
              {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
            </Grid>

            <Grid item xs={12} sm={6} className="py-0">
              <NewTextField
                info="Enter email id of user"
                disabled={userId}
                required
                fullWidth
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                label="Email"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('email', event.target.value)}
                value={values.email}
                variant="outlined"
              />
              {serverErrors.email && <p className="text-danger mt-n2">{serverErrors.email}</p>}
            </Grid>
            <Grid item xs={12} sm={6} className="py-0">
              <NewSelect
                info="Enable the status to create account. Disable it when not required"
                options={USER_STATUS}
                value={values.status}
                onChange={(obj) => setFieldValue('status', obj)}
                placeholder={`Status`}
                className="py-2"
              />{' '}
              {serverErrors.status && <p className="text-danger mt-n2">{serverErrors.status}</p>}
            </Grid>
            <Grid item xs={12} sm={6} className="py-0">
              <NewSelect
                info="Select role of user to give access to account as per the role. e.g Admin, Viewer "
                options={USER_ROLE_OPTIONS}
                value={values.roleList}
                onChange={(obj) => setFieldValue('roleList', obj)}
                placeholder={`Role`}
                className="py-2"
              />{' '}
              {serverErrors.roleList && (
                <p className="text-danger mt-n2">{serverErrors.roleList}</p>
              )}
            </Grid>

            {!userId && (
              <>
                <Grid item xs={12} sm={6} className="py-0">
                  <NewTextField
                    info="Enter password "
                    type="password"
                    required
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('password', event.target.value)}
                    value={values.password}
                    variant="outlined"
                  />{' '}
                  {serverErrors.password && (
                    <p className="text-danger mt-n2">{serverErrors.password}</p>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} className="py-0">
                  <NewTextField
                    info="Re-enter password to confirm"
                    type="password"
                    required
                    fullWidth
                    error={Boolean(touched.rePassword && errors.rePassword)}
                    helperText={touched.rePassword && errors.rePassword}
                    label="Confirm Password"
                    margin="normal"
                    name="rePassword"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('rePassword', event.target.value)}
                    value={values.rePassword}
                    variant="outlined"
                  />{' '}
                  {serverErrors.rePassword && (
                    <p className="text-danger mt-n2">{serverErrors.rePassword}</p>
                  )}
                </Grid>
              </>
            )}
          </Grid>
          {!isSuperAdmin && (
            <div className="mt-4 d-flex justify-content-end ">
              <Button
                variant="contained"
                className="btn btn-secondary d-flex"
                type="button"
                onClick={() => history.push(`/user`)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="btn btn-primary d-flex ml-4"
                type="submit"
                disabled={isLoadingCreateUser || !values.name}
              >
                {!!userId
                  ? isLoadingCreateUser
                    ? 'Updating'
                    : 'Update'
                  : isLoadingCreateUser
                  ? 'Creating'
                  : 'Create'}
              </Button>
            </div>
          )}
        </form>
      </Paper>
      {isLoadingCreateUser && <OverlapLoader />}
    </div>
  );
};

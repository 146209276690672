import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import Services from '../../service-utils/services';
import { useSnackbar } from 'notistack';
import { NewTextField, Paper, Grid, NewSelect, Button, Typography } from '../../common';
import { handleMsgOnForm } from '../../common/utils';
import history from '../../history';
import { useHistory } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
export const AdvTargeting = (props) => {
  const { lineItemId, campaignId, advertiserId, agencyId, goToNextStep, lineItemObjective } = props;
  const [pinCodes, setPinCodes] = useState('');
  const [serverErrors, setServerErrors] = useState({});
  let history = useHistory();
  const { data, isLoading } = useQuery(
    ['ADV_TARGETTING', advertiserId, campaignId, lineItemId],
    async () => {
      const response = await Services.getAdvTargetings(
        advertiserId,
        campaignId,
        lineItemId,
        agencyId,
      );
      return response.data?.targetingPinCodeDTO || {};
    },
    { enabled: !!agencyId && !!lineItemId },
  );

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: handleSubmit, isLoading: isLoadingHandleSubmit } = useMutation(
    async () => {
      const payload = {
        pinCode: pinCodes,
        // lineItemId,
      };
      const response = await Services.addUpdatePinCodes(
        advertiserId,
        campaignId,
        lineItemId,
        payload,
        agencyId,
      );
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        enqueueSnackbar('Pincodes updates successfully.', { variant: 'success' });
        goToNextStep();
      },
    },
  );

  useEffect(() => {
    if (!isEmpty(data?.pinCode)) {
      setPinCodes(data.pinCode);
    }
  }, [data]);

  return (
    <>
      <Grid container spacing={3} className="p-4">
        <Grid item xs={12} className="py-0">
          <NewTextField
            fullWidth
            info="Enter the pincodes of the areas you want to target, If required."
            label="Pin Codes"
            margin="normal"
            name="pinCodes"
            onChange={(event) => setPinCodes(event.target.value)}
            value={pinCodes}
            variant="outlined"
            multiline
            rows={3}
          />
        </Grid>
        <Typography className="ml-3">
          Pincodes should be comma separated (e.g. 400001,400002,etc.)
        </Typography>
      </Grid>

      <div className="my-4 d-flex justify-content-end ">
        <Button
          variant="contained"
          className="btn btn-secondary d-flex mr-4"
          onClick={history.goBack}
        >
          Cancel
        </Button>
        {lineItemObjective === 'LEAD' && (
          <Button
            variant="contained"
            className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              goToNextStep(lineItemId);
            }}
            // disabled={expired}
          >
            Next
          </Button>
        )}
        <Button
          variant="contained"
          type="submit"
          className="btn btn-primary d-flex ml-4"
          onClick={handleSubmit}
        >
          {'Submit'}
        </Button>
      </div>
      {isLoadingHandleSubmit && <OverlapLoader />}
    </>
  );
};

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { Table, SearchInput } from '../../components';
import { Button, Loader, Typography, NewSelect } from '../../common';
import Services from '../../service-utils/services';
import getSessionData from '../../service-utils/session-util';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import FilterListIcon from '@material-ui/icons/FilterList';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { handleMsgOnForm } from '../../common/utils';
import Popover from 'react-bootstrap/Popover';
import Paper from '@material-ui/core/Paper';
import { useParams } from 'react-router-dom';
import '../../../src/main.scss';
import { CACHE_TIME, STALE_TIME } from '../../common/constants';
import { dateFormatMoment } from '../../components/format-date-moment';

export const InventoryPlacements = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { clusterId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = queryString.parse(location.search);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [appliedSearchText, setAppliedSearchText] = useState('');
  const [trafficType, setTrafficType] = useState();
  const [appliedTrafficType, setAppliedTrafficType] = useState();

  const [totalSelectedPlacements, setTotalSelectedPlacements] = useState([]);
  const [newSelectedPlacements, setNewSelectedPlacements] = useState([]);
  const [alreadyConfiguredPlacements, setAlreadyConfiguredPlacements] = useState([]);

  let { agencyId, advertiserId } = getSessionData();
  const [serverErrors, setServerErrors] = useState({});

  const { data: trafficTypesData = [] } = useQuery(
    ['TRAFFIC_TYPE_MASTER'],
    async () => {
      const payload = {
        agencyId: agencyId,
      };
      const response = await Services.getMasterTrafficType(queryString.stringify(payload));
      return response.data?.masterList;
    },
    {
      enabled: !!clusterId && !!agencyId,
      staleTime: STALE_TIME.HALF_HOUR,
      cacheTime: CACHE_TIME.HALF_HOUR,
    },
  );

  const {
    data,
    isLoading,
    refetch: refetchData,
  } = useQuery(
    ['PLACEMENTS_DATA', pageLimit, pageNumber, agencyId, appliedSearchText, appliedTrafficType],
    async () => {
      if (queryParams.configuredOnly !== 'true') {
        const queryStringParams = {
          pageNum: pageNumber - 1,
          pageSize: pageLimit,
          agencyId,
          clusterId,
          keyword: appliedSearchText,
          trafficType: appliedTrafficType,
        };
        const response = await Services.placementSearch(queryString.stringify(queryStringParams));
        return response.data;
      } else if (queryParams.configuredOnly === 'true') {
        // console.log('asdasd');
        const payload = {
          agencyId,
          pageNum: pageNumber - 1,
          pageSize: pageLimit,
          keyword: appliedSearchText,
          trafficType: appliedTrafficType,
        };
        const response = await Services.clusterGetDetails(
          advertiserId,
          clusterId,
          queryString.stringify(payload),
        );
        return response.data;
      }
    },
    { enabled: !!agencyId },
  );

  const { data: clusterData = {}, refetch: refetchCluster } = useQuery(
    ['CLUSTER_DATA', advertiserId, clusterId, agencyId],
    async () => {
      const payload = {
        agencyId,
      };
      const response = await Services.clusterGetDetails(
        advertiserId,
        clusterId,
        queryString.stringify(payload),
      );
      return response.data;
    },
    {
      enabled: !!clusterId && !!agencyId,
    },
  );

  const { mutate: removePlacemetsFromCluster } = useMutation(
    async (payload) => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.removePlacemetsFromCluster(
        advertiserId,
        clusterId,
        query,
        payload,
      );
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data, ids) => {
        refetchCluster();
        refetchData();
        hideRowLoader(ids);
      },
    },
  );

  const { mutate: addPlacemetsToCluster } = useMutation(
    async (payload) => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.addPlacemetsToCluster(
        advertiserId,
        clusterId,
        query,
        payload,
      );
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data, ids) => {
        refetchCluster();
        refetchData();
        hideRowLoader(ids);
      },
    },
  );

  useEffect(() => {
    if (queryParams.configuredOnly === 'true') {
      if (!isEmpty(data?.placementList)) {
        setAlreadyConfiguredPlacements(data.placementList);
      }
    }
    if (queryParams.configuredOnly !== 'true') {
      if (!isEmpty(data?.placementList)) {
        setAlreadyConfiguredPlacements(data.placementList?.filter((item) => item.checked));
      }
    }
  }, [data]);

  const trafficTypeOptions = useMemo(() => {
    return (
      trafficTypesData?.map((item) => ({
        ...item,
        value: item.id,
      })) || []
    );
  }, [trafficTypesData]);

  const handleApply = useCallback(() => {
    setPageNumber(1); // Reset page number to 1
    setAppliedSearchText(searchText);
    setAppliedTrafficType(trafficType?.value);
  }, [searchText, trafficType]);

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            {queryParams.configuredOnly === 'true' ? 'Already Configured Placements' : 'Placements'}
          </div>
        ),
        back: {
          text: 'Back',
        },
      }),
    );
  }, [dispatch]);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };

  const tableData = useMemo(() => {
    return data?.placementList || [];
  }, [data]);

  const getTableHeader = [
    {
      dataField: 'placementName',
      text: 'App/Site Name',
      style: {
        width: '15%',
        position: 'sticky',
        left: '0',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
    {
      dataField: 'createdDate',
      text: 'Date',
      formatter: (col, row) => dateFormatMoment(col, 'DD MMM YY hh:mm A'),
      style: {
        width: '15%',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
    {
      dataField: 'placementBundleId',
      text: 'App Bundle Id',
      style: {
        width: '30%',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      }, // 30% width
    },
    {
      dataField: 'placementDomain',
      text: 'App/Site Domain',
      style: {
        width: '30%',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      }, // 30% width
    },
    {
      dataField: 'trafficType',
      text: 'Traffic Type',
      style: {
        width: '10%',
        position: 'sticky',
        right: '0',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      }, // 10% width
    },
  ];

  const configuredPlacements = (
    <Popover style={{ 'min-width': '500px' }}>
      <Paper className="p-0 mn-h-100 chart-container">
        <div className="p-3">
          <Typography component="h5" variant="h6" className="mb-1">
            Configured Placements
          </Typography>
          <div className="border mb-2 p-1" style={{ 'min-height': '40px', 'max-height': '400px' }}>
            {alreadyConfiguredPlacements?.map((item) => {
              return (
                <Chip
                  style={{ margin: '2px' }}
                  label={item.placementName || `Domain-${item.placementDomain}`}
                  onDelete={() => {
                    removePlacemetsFromCluster([item.id]);
                  }}
                />
              );
            })}
          </div>
        </div>
      </Paper>
    </Popover>
  );

  /*
  const selectedCluster = (
    <Popover style={{ "min-width": "500px" }}>
      <Paper className="p-0 mn-h-100 chart-container">
        <div className="p-3">
          <Typography component="h5" variant="h6" className="mb-1">
            Selected Placements
          </Typography>
          <div className="border mb-2 p-1" style={{  "min-height": "40px","max-height": "400px" }}>
            <Chip
              className="px-1"
              label="placement1"
              onDelete={() => {
                console.log("delete");
              }}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              className="btn btn-primary d-flex btn-38 ml-3"
              onClick={() => {}}
            >
              Update
            </Button>
          </div>
        </div>
      </Paper>
    </Popover>
  );
  */

  const showRowLoader = (ids) => {
    ids.map((id) => {
      const divEle = document.getElementsByClassName(`cluster-placement-row-${id}`)[0];
      divEle.classList.add('loadingCheckbox');
      const tdEle = divEle.getElementsByClassName(`selection-cell`)[0];
      var d = document.createElement('div');
      d.className = 'loading-ring';
      tdEle.appendChild(d);
    });
  };

  const hideRowLoader = (ids) => {
    ids.map((id) => {
      document
        .getElementsByClassName(`cluster-placement-row-${id}`)[0]
        .classList.remove('loadingCheckbox');
    });
  };

  const selectRow = useMemo(() => {
    // console.log(' [...alreadyConfiguredPlacements, ...newSelectedPlacements]', [
    //   ...alreadyConfiguredPlacements,
    //   ...newSelectedPlacements,
    // ]);
    return {
      mode: 'checkbox',
      //bgColor: 'red',
      //classes: (row, rowIndex) => { return "text-----new" },
      clickToSelect: false,
      selected: [...alreadyConfiguredPlacements, ...newSelectedPlacements].map((item) => item.id),

      onSelect: (row, isSelect, rowIndex, e) => {
        isSelect && addPlacemetsToCluster([row.id]);
        !isSelect && removePlacemetsFromCluster([row.id]);
        showRowLoader([row.id]);
      },
      onSelectAll: (isSelect, rows) => {
        const allRowIds = rows.map((item) => item.id);
        isSelect && addPlacemetsToCluster(allRowIds);
        !isSelect && removePlacemetsFromCluster(allRowIds);
        showRowLoader(allRowIds);
      },
    };
  }, [
    alreadyConfiguredPlacements,
    newSelectedPlacements,
    addPlacemetsToCluster,
    removePlacemetsFromCluster,
  ]);

  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex inventory-placement-header">
          <Typography variant="subtitle1" gutterBottom>
            Selected Cluster: {clusterData?.cluster?.name ?? ''}
          </Typography>
        </div>
      </div>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex">
          <SearchInput
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText || ''}
          />
          <NewSelect
            required
            isClearable
            options={trafficTypeOptions}
            value={trafficType}
            onChange={(obj) => setTrafficType(obj)}
            placeholder={`Traffic Type`}
            isMulti={false}
            className="mt-n3 ml-3 "
          />
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38 ml-3"
            startIcon={<FilterListIcon />}
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>

        {/*<OverlayTrigger
          trigger="click"
          placement="left"
          overlay={configuredPlacements}
          rootClose={true}
        >
          <Typography>Already Configured placemetns : {clusterData?.totalElements || '0'}</Typography>
        </OverlayTrigger>*/}
        {queryParams.configuredOnly === 'true' ? (
          <Typography>
            Already Configured placement(s) : {clusterData?.totalElements || '0'}
          </Typography>
        ) : (
          <Typography>
            Total configured placement(s) : {clusterData?.totalElements || '0'}
          </Typography>
        )}
      </div>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex">
          <Typography variant="paraText" gutterBottom>
            * Note: Please tick/untick the checkbox to select/unselect placement. Placement will be
            automatically added to or removed from selected Cluster.
          </Typography>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        clusterData?.cluster && (
          <Table
            keyField="id"
            tableData={tableData}
            className="custom-table-cell"
            tableHeader={getTableHeader}
            isRemote={true}
            onPageChangeFun={handleTableChange}
            totalSize={data?.totalElements}
            activePage={pageNumber}
            sizePerPage={pageLimit}
            wrapperClasses="cluster-placement-table"
            rowClasses={(row) => {
              return `cluster-placement-row-${row.id}`;
            }}
            defaultSorted={[
              {
                dataField: 'firstName',
                order: 'asc',
              },
            ]}
            selectRow={
              clusterData?.cluster?.agencyId?.toString() === agencyId?.toString() &&
              clusterData?.cluster?.advertiserId?.toString() === advertiserId?.toString()
                ? selectRow
                : clusterData?.cluster?.readOnly
                ? undefined
                : selectRow
            }
          />
        )
      )}
    </>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { Table } from '../../components';
import { Button, Loader } from '../../common';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { Dropdown } from 'react-bootstrap';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useSnackbar } from 'notistack';
import getSessionData from '../../service-utils/session-util';
import Chip from '@material-ui/core/Chip';
import { ConformationBox } from '../../components/conformationBox';
import { getErrorMsg } from '../../common/utils';
import { UserMenu } from '../../hoc/layout/side-navbar/UserMenu';

export const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { agencyId, user } = getSessionData();
  const [deleteId, setDeleteId] = useState();
  const [disabledId, setDisabledId] = useState();
  const [enableId, setEnableId] = useState();

  //console.log('user',user)
  const { data, isLoading, refetch } = useQuery(['USER_DATA', pageNumber, pageLimit], async () => {
    const queryParams = {
      pageNum: pageNumber - 1,
      pageSize: pageLimit,
      agencyId,
    };
    const response = await Services.getAgencyUsers(queryString.stringify(queryParams));
    return response.data;
  });

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Users
          </div>
        ),
      }),
    );
  }, [dispatch]);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };

  const handleAddUser = () => {
    history.push(`/user-create`);
  };

  const handleEditUser = (userId) => {
    if (userId) {
      history.push(`/user/${userId}`);
    }
  };

  const { mutate: handleDeleteUser } = useMutation(
    async (userId) => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.userDelete(userId, query);
      return response.data;
    },
    {
      onError: (err) => {
        enqueueSnackbar(getErrorMsg(err, 'Error in deleting a user.'), { variant: 'error' });
        setDeleteId();
      },
      onSuccess: () => {
        enqueueSnackbar('User deleted successfully.', { variant: 'success' });
        setDeleteId();
        refetch();
      },
    },
  );

  const { mutate: handleStatusChange } = useMutation(
    async (payload) => {
      const { userId, status } = payload;
      const response = await Services.userStatusUpdate(userId, status, agencyId);
      return response.data;
    },
    {
      onError: (err, data) => {
        //console.log('err',err,data)
        enqueueSnackbar('Error in updating status.', { variant: 'error' });
      },
      onSuccess: (a, b) => {
        //console.log(a,b)
        refetch();
        setDisabledId();
        setEnableId();
        enqueueSnackbar('Updated status successfully.', { variant: 'success' });
      },
    },
  );

  const tableData = useMemo(() => {
    return data?.users || [];
  }, [data]);

  const manageButton = (row) => {
    return (
      <>
        <Dropdown id="dropdown-item-button" className="action-dropdown btn-action">
          <Dropdown.Toggle variant="link">
            <MoreHorizIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleEditUser(row.id)}>Edit</Dropdown.Item>
            {!row.enabled && (
              <Dropdown.Item onClick={() => setEnableId(row.id)}>Enable User</Dropdown.Item>
            )}
            {row.enabled && (
              <Dropdown.Item onClick={() => setDisabledId(row.id)}>Disable User</Dropdown.Item>
            )}
            <Dropdown.Item onClick={() => setDeleteId(row.id)}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const getTableHeader = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (col, row) => <a href={`/user/${row.id}`}>{col}</a>,
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'roleList',
      text: 'Roles',
      formatter: (col) => {
        //const roles = col?.map(item => item);
        return col.toString();
      },
    },
    {
      dataField: 'enabled',
      text: 'Status',
      formatter: (col, row) =>
        col ? (
          <Chip label="Enabled" className="alert-success" />
        ) : (
          <Chip label="Disabled" className="alert-danger" />
        ),
    },
    {
      dataField: 'id',
      text: '',
      formatter: (col, row) => (row.email !== user ? manageButton(row) : <></>),
    },
  ];
  return (
    <>
      <div className="mb-3 d-flex justify-content-end filters">
        <Button
          variant="contained"
          className="btn btn-primary d-flex btn-38"
          // startIcon={<AddIcon />}
          onClick={() => handleAddUser()}
        >
          New User
        </Button>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={tableData}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'name',
              order: 'asc',
            },
          ]}
        />
      )}
      <ConformationBox
        isOpen={!!disabledId}
        handleClose={() => setDisabledId()}
        handleSubmit={() => handleStatusChange({ userId: disabledId, status: false })}
        title="Disable ?"
        subtitle="Are you sure you want to disable this user?"
        btnCloseLabel="Close"
        btnSubmitLabel="Disable"
      />
      <ConformationBox
        isOpen={!!enableId}
        handleClose={() => setEnableId()}
        handleSubmit={() => handleStatusChange({ userId: enableId, status: true })}
        title="Enable ?"
        subtitle="Are you sure you want to enable this user?"
        btnCloseLabel="Close"
        btnSubmitLabel="Enable"
      />
      <ConformationBox
        isOpen={!!deleteId}
        handleClose={() => setDeleteId()}
        handleSubmit={() => handleDeleteUser(deleteId)}
        title="Delete ?"
        subtitle="Are you sure you want to delete this user?"
        btnCloseLabel="Close"
        btnSubmitLabel="Delete"
      />
    </>
  );
};

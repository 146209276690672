import React from 'react';
import { Grid, Card, Tooltip } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import getSessionData from '../../service-utils/session-util';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import budgetIcon from '../../assets/icon/budget.png';
import spendIcon from '../../assets/icon/spend.png';
import { DashboardOverviewSummary } from '../../service-utils/useApis';
import { getCurrencySymbol } from './../../common/utils';
import { numberFormatter } from './../../common/utils';

const SummaryBox = (props) => {
  const { title = '', icon, image, value, valuesData, titles, tooltipValue } = props;

  return (
    <Card className="d-flex p-2">
      <div className="d-flex w-100 align-items-center">
        <div className="w-25 summary-icon">{icon ? icon : <img alt="image" src={image} />}</div>
        <div className="w-75">
          <Tooltip title={<Typography className="TooltipFontSize">{tooltipValue}</Typography>}>
            <CardContent className="p-2 mr-5">
              <div className="filters d-flex justify-content-between">
                <Typography className="mt-1 font20 summarytitleColor">{title || ''}</Typography>
                <Typography className="mr-2 h4 font30">{value || ''}</Typography>
              </div>

              <div className="dotted-line font20 summaryBoxBorder"></div>
              <div className="filters d-flex justify-content-between">
                <Typography className="h4 mt-1 font30">{valuesData || ''}</Typography>
                <Typography className="mt-2 font20 summarytitleColor">{titles || ''}</Typography>
              </div>
            </CardContent>
          </Tooltip>
        </div>
      </div>
    </Card>
  );
};
//Total impression, CTR,	Budget,	total spent
export const Summary = () => {
  const { agencyId, agencyCurrency } = getSessionData();
  const { data = {} } = DashboardOverviewSummary(agencyId, agencyCurrency);
  // function kFormatter(num) {
  //     return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
  // }

  let eCPC = data.click ? data.totalSpent?.amount / data.click : 0;
  let eCPM = data.totalImpressions ? (data.totalSpent?.amount * 1000) / data.totalImpressions : 0;
  return (
    <div className="summary-box">
      <Grid container spacing={3}>
        <Grid item xs={4} className="d-grid ">
          <SummaryBox
            title="Impressions"
            icon={<RemoveRedEyeIcon className="font50 primary-color" />}
            value={numberFormatter(data?.totalImpressions) || 0}
            valuesData={
              `${getCurrencySymbol(data?.totalSpent?.currency)} ${numberFormatter(eCPM)}` || 0
            }
            titles="eCPM"
            tooltipValue={`Impressions: ${data?.totalImpressions || 0}`}
          />
        </Grid>
        <Grid item xs={4} className="d-grid">
          <SummaryBox
            title="Clicks"
            icon={<TouchAppIcon className="font50 primary-color" />}
            value={` ${numberFormatter(data?.click || 0)}`}
            valuesData={`${data?.ctr !== undefined ? `${data.ctr}%` : '0%'}`}
            titles="CTR"
            tooltipValue={`Clicks: ${data?.click || 0}`}
          />
        </Grid>

        <Grid item xs={4} className="d-grid ">
          <SummaryBox
            title="Total Spent"
            image={spendIcon}
            value={
              `${getCurrencySymbol(data?.totalSpent?.currency)} ${numberFormatter(
                data?.totalSpent?.amount,
              )}` || 0
            }
            valuesData={
              `${getCurrencySymbol(data?.totalSpent?.currency)} ${numberFormatter(eCPC)}` || 0
            }
            titles="eCPC"
            tooltipValue={
              `Total Spent: ${getCurrencySymbol(data?.totalSpent?.currency)}
               ${data?.totalSpent?.amount}` || 0
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

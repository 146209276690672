import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import { NewSelect, NewTextField } from '../../common';
import getSessionData from './../../service-utils/session-util';
import { handleMsgOnForm } from './../../common/utils';
import { AgencyCreditBalance } from './../../service-utils/useApis';
import { useLocation } from 'react-router-dom';
import './account.scss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { GENERATE_INVOICE } from '../../libs/constants';
const AdminCreditAmount = ({
  refetchLedgerDetails,
  refetchGetBalance,
  handleBackButton,
  currency,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { agencyId, roles } = getSessionData();
  const [addAmount, setAddAmount] = useState('');
  const [note, setNote] = useState('');
  const [gstAmount, setGstAmount] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [isGenerateInvoice, setisGenerateInvoice] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [paymentMethodType, setPaymentMethodType] = useState('');
  const [bankid, setBankid] = useState('');
  const [serverErrors, setServerErrors] = useState({});
  const urlUserAgencyId = new URLSearchParams(location.search).get('agencyId');
  const userIsSuperadmin = roles.includes('SUPERADMIN');
  const userAgencyId = userIsSuperadmin ? urlUserAgencyId : agencyId;

  const onError = (err) => {
    handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
  };

  const onSuccess = () => {
    enqueueSnackbar('Points credited to the wallet', { variant: 'success' });
    handleBackButton();
    refetchLedgerDetails();
    refetchGetBalance();
  };

  const { mutate: creditAmount, isLoading: isLoadingCreditAmount } = AgencyCreditBalance(
    agencyId,
    userAgencyId,
    {
      addAmount,
      note,
      gstAmount,
      totalAmount,
      transactionId,
      bankid,
      paymentMethodType,
      currency,
      isGenerateInvoice,
    },
    { onError, onSuccess },
  );
  console.log(isGenerateInvoice);
  const handleSubmit = (e) => {
    e.preventDefault();
    creditAmount();
  };

  useEffect(() => {
    if (addAmount !== '') {
      const gst = (parseFloat(addAmount) * 18) / 100;
      const roundedGST = Math.floor(gst); // Round down to remove decimal part
      const calculatedGstAmount = isNaN(roundedGST) ? '0' : roundedGST.toString();
      setGstAmount(calculatedGstAmount);

      const total = parseFloat(addAmount) + parseFloat(calculatedGstAmount);
      const roundedTotal = Math.floor(total); // Round down to remove decimal part
      const calculatedTotalAmount = isNaN(roundedTotal) ? '0' : roundedTotal.toString();
      setTotalAmount(calculatedTotalAmount);
    } else {
      setGstAmount();
      setTotalAmount();
    }
  }, [addAmount]);

  return (
    <>
      <div className="back-button-container">
        <button
          className="btn btn-md btn-link p-0 d-flex align-items-center"
          onClick={() => handleBackButton()}
        >
          <ArrowBackIcon />
          Back
        </button>
      </div>
      <div className="payment-form centered-form">
        <Typography color="textPrimary" variant="h5">
          Add Balance
        </Typography>
        <div className="d-flex justify-content-between align-items-center">
          <div className="p-2">
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <NewTextField
                    required
                    variant="outlined"
                    label="Enter Amount"
                    margin="normal"
                    type="number"
                    fullWidth
                    value={addAmount}
                    onChange={(e) => setAddAmount(e.target.value)}
                    error={!!serverErrors.amount}
                    helperText={serverErrors.amount}
                    inputProps={{ min: 100 }}
                  />
                </Grid>
                <Grid className="form-field" item xs={12}>
                  <NewTextField
                    variant="outlined"
                    margin="normal"
                    label="GST (18%)"
                    id="gst"
                    name="gst"
                    type="text"
                    fullWidth
                    disabled
                    value={gstAmount}
                    InputProps={{
                      readOnly: true,
                    }}
                    className="myCustomTextField textField"
                  />
                </Grid>
                <Grid className="form-field" item xs={12}>
                  <NewTextField
                    variant="outlined"
                    label="Payable Amount"
                    margin="normal"
                    id="totalAmount"
                    name="totalAmount"
                    type="text"
                    fullWidth
                    disabled
                    value={totalAmount}
                    InputProps={{
                      readOnly: true,
                    }}
                    className="myCustomTextField textField font-weight-bold"
                  />
                </Grid>
                <Grid item xs={12} sm={12} className="py-0">
                  <NewSelect
                    required
                    // info="Enable the status to start campaign. Disable to pause it"
                    options={GENERATE_INVOICE}
                    value={isGenerateInvoice} // This should be a boolean
                    onChange={(e) => {
                      // Convert the value to boolean
                      setisGenerateInvoice(e);
                    }}
                    placeholder={`Generate Invoice`}
                    className="py-2"
                  />

                  {serverErrors.isGenerateInvoice && (
                    <p className="text-danger mt-n2">{serverErrors.isGenerateInvoice}</p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <NewTextField
                    variant="outlined"
                    label="Note"
                    margin="normal"
                    fullWidth
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    error={!!serverErrors.note}
                    helperText={serverErrors.note}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NewTextField
                    required
                    variant="outlined"
                    label="TransactionId"
                    margin="normal"
                    fullWidth
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    error={!!serverErrors.transactionId}
                    helperText={serverErrors.transactionId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NewTextField
                    required
                    variant="outlined"
                    label="Payment Method Type"
                    margin="normal"
                    info="Payment Method Type Like UPI,Cash,NetBanking"
                    fullWidth
                    value={paymentMethodType}
                    onChange={(e) => setPaymentMethodType(e.target.value)}
                    error={!!serverErrors.paymentMethodType}
                    helperText={serverErrors.paymentMethodType}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NewTextField
                    variant="outlined"
                    label="Bankid"
                    margin="normal"
                    fullWidth
                    value={bankid}
                    onChange={(e) => setBankid(e.target.value)}
                    error={!!serverErrors.bankid}
                    helperText={serverErrors.bankid}
                  />
                </Grid>
              </Grid>
              <div className="d-flex justify-content-center align-items-center mt-3">
                <Button
                  type="submit"
                  id="rzp-button1"
                  className="btn btn-primary mt-2 w-40 button-container"
                  size="medium"
                  disabled={isLoadingCreditAmount || !addAmount}
                >
                  {isLoadingCreditAmount ? 'Submitting' : 'Submit'}
                </Button>
              </div>
            </form>
            {isLoadingCreditAmount && <OverlapLoader />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCreditAmount;

import Cookies from 'js-cookie';

const CookieUtil = () => {
  const cookieOptions = { domain: window.location.hostname };
  const setAppCookie = (name, value) => Cookies.set(name, value, cookieOptions);

  const removeAppCookie = (name) => Cookies.remove(name, cookieOptions);

const removeAllCookie = () =>{
  setAppCookie("agencyCurrency", null);
  removeAppCookie("access_token");
  removeAppCookie("refresh_token");
  removeAppCookie("user");
  removeAppCookie("userId");
  removeAppCookie("agencyId");
  removeAppCookie("agencyCurrency");
  removeAppCookie("roles");
  removeAppCookie("userFullName");
}

  const getCookies = () => {
    return Cookies.get();
  };

  return {
    getCookies,
    setAppCookie,
    removeAppCookie,
    removeAllCookie,
  };
};

export default CookieUtil;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, Button, Typography } from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from './../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import { useParams } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
const CreativeClone = () => {
  const { creativeId } = useParams();

  let { agencyId, advertiserId } = getSessionData();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: '/advertiser/creative',
        },
      }),
    );
  }, [dispatch]);

  const { data: creativeData = {} } = useQuery(
    ['CREATIVE_DATA', creativeId, agencyId, advertiserId],
    async () => {
      const payload = { agencyId };
      const response = await Services.creativeGetDetails(
        advertiserId,
        creativeId,
        queryString.stringify(payload),
      );
      return response.data?.creativeDTO;
    },
    { enabled: !!creativeId && !!agencyId },
  );

  const { mutate: cloneCreative, isLoading: isLoadingCloneCreative } = useMutation(
    async (values) => {
      const { name } = values;
      const payload = {
        name: name,
      };
      const query = queryString.stringify({ agencyId });
      const response = await Services.creativeClone(advertiserId, creativeId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        enqueueSnackbar('Creative clone successfully.', { variant: 'success' });
        history.push(`/advertiser/creative`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: creativeData?.name || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Creative name is required.'),
    }),
    onSubmit: () => cloneCreative(values),
  });
  useEffect(() => {
    if (creativeData?.name) {
      const newName = `${creativeData.name}_Copy`;
      setFieldValue('name', newName);
    }
  }, [creativeData, setFieldValue]);

  return (
    <div>
      <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
        <Paper className="pt-3 p-4 mn-h-200">
          <div className="d-flex justify-content-center mb-3">
            <Typography color="textPrimary" variant="h5">
              Clone Creative
            </Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} className="py-0">
                <NewTextField
                  required
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Creative Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('name', event.target.value)}
                  value={values.name}
                  variant="outlined"
                />
                {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
              </Grid>
            </Grid>
            <div className="mt-4 d-flex justify-content-end ">
              <Button
                variant="contained"
                class="btn btn-secondary d-flex"
                onClick={() => history.push(`/advertiser/creative`)}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-4">
                {isLoadingCloneCreative ? 'Creating' : 'Create'}
              </Button>
              {isLoadingCloneCreative && <OverlapLoader />}
            </div>
          </form>
        </Paper>
      </div>
    </div>
  );
};

export default CreativeClone;

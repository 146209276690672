import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, NewSelect, Button, Typography } from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { ADVERTISER_STATUS } from '../../libs/constants';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import getSessionData from './../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import OverlapLoader from '../../common/loader/OverlapLoader';

export const AdvertiserCreate = () => {
  const { advertiserId } = useParams();
  const dispatch = useDispatch();
  let { agencyId } = getSessionData();

  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
        },
      }),
    );
  }, [dispatch]);

  const { data: advertiserData = {} } = useQuery(
    ['ADVERTISER_DATA', advertiserId],
    async () => {
      const response = await Services.advertiserGetDetails(advertiserId, agencyId);
      return response.data?.advertiser;
    },
    { enabled: !!advertiserId && !!agencyId },
  );

  const { mutate: createAdvertiser, isLoading: isLoadingCreateAdvertiser } = useMutation(
    async (values) => {
      const {
        advertiserName,
        seat,
        isDealSupported,
        status,
        state,
        city,
        country,
        contact,
        address,
        details,
      } = values;
      const payload = {
        name: advertiserName,
        seat: seat,
        isDealSupported: isDealSupported,
        enabled: status?.value,
        state: state,
        city: city,
        country: country,
        phoneNumber: contact,
        address: address,
        details: details,
        agencyId,
      };
      const { data } = !!advertiserId
        ? await Services.advertisersUpdate(advertiserId, agencyId, payload)
        : await Services.advertisersCreate(agencyId, payload);
      return data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data) => {
        if (!!advertiserId) {
          enqueueSnackbar('Advertiser updated successfully.', { variant: 'success' });
        } else {
          enqueueSnackbar('Advertiser created successfully.', { variant: 'success' });
        }
        history.push(`/customer`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      advertiserName: '',
      contact: '',
      isDealSupported: false,
      seat: '',
      status: true,
      country: '',
      state: '',
      city: '',
      address: '',
      details: '',
    },
    validationSchema: Yup.object({
      advertiserName: Yup.string().max(255).required('Advertiser name is required.'),
    }),
    onSubmit: () => createAdvertiser(values),
  });

  useEffect(() => {
    if (!isEmpty(advertiserData)) {
      const advStatus =
        ADVERTISER_STATUS.filter((item) => item.value === advertiserData?.enabled)[0] ||
        ADVERTISER_STATUS[0];
      !isEmpty(advStatus) && setFieldValue('status', advStatus);
      advertiserData?.name && setFieldValue('advertiserName', advertiserData.name);
      advertiserData?.contact && setFieldValue('contact', advertiserData.contact);
      advertiserData?.dealSupported &&
        setFieldValue('isDealSupported', advertiserData.dealSupported);
      advertiserData?.seat && setFieldValue('seat', advertiserData.seat);
      advertiserData?.country && setFieldValue('country', advertiserData.country);
      advertiserData?.state && setFieldValue('state', advertiserData.state);
      advertiserData?.city && setFieldValue('city', advertiserData.city);
      advertiserData?.address && setFieldValue('address', advertiserData.address);
      advertiserData?.details && setFieldValue('details', advertiserData.details);
    }
  }, [advertiserData, setFieldValue]);

  return (
    <div className="col-md-10 bg-white rounded shadow m-auto mb-4 p-0">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            {!!advertiserId ? 'Edit Advertiser' : 'Create New Advertiser'}
          </Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className="py-0">
              <NewTextField
                required
                fullWidth
                info="Enter advertiser name e.g Asian Paints, Red Label etc"
                error={Boolean(touched.advertiserName && errors.advertiserName)}
                helperText={touched.advertiserName && errors.advertiserName}
                label="Advertiser Name"
                margin="normal"
                name="advertiserName"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('advertiserName', event.target.value)}
                value={values.advertiserName}
                variant="outlined"
              />
              {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
            </Grid>

            {/* <Grid item xs={12} sm={6} className="py-0">
              <NewTextField
                fullWidth
                helperText={touched.contact && errors.contact}
                error={Boolean(touched.contact && errors.contact)}
                label="Contact"
                margin="normal"
                name="contact"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('contact', event.target.value)}
                value={values.contact}
                variant="outlined"
              />
              {serverErrors.contact && <p className="text-danger mt-n2">{serverErrors.contact}</p>}
            </Grid> */}
            <Grid item xs={12} sm={6} className="py-0">
              <NewSelect
                info="Enable status to start advertising. Disable when advertiser is not in use"
                required
                options={ADVERTISER_STATUS}
                value={values.status}
                onChange={(obj) => setFieldValue('status', obj)}
                placeholder={`Status`}
                className="py-2"
              />
            </Grid>

            <Grid item xs={12} sm={6} className="py-0">
              <NewTextField
                fullWidth
                info="Enter seat id for programmatic guaranteed deals"
                helperText={touched.seat && errors.seat}
                error={Boolean(touched.seat && errors.seat)}
                label="Seat"
                margin="normal"
                name="seat"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('seat', event.target.value)}
                value={values.seat}
                variant="outlined"
              />
              {serverErrors.seat && <p className="text-danger mt-n2">{serverErrors.seat}</p>}
            </Grid>
            <Grid item xs={12} sm={6} className="py-0">
              <FormControlLabel
                className="pt-4"
                control={
                  <Checkbox
                    checked={values.isDealSupported}
                    onChange={(event, val) => {
                      setFieldValue('isDealSupported', val);
                    }}
                    name="checkedB"
                    color="primary"
                    className="m-0"
                  />
                }
                label="Is deal supported"
              />
            </Grid>

            {/* <Grid item xs={12} sm={6} className="py-0">
              <NewTextField
                error={Boolean(touched.country && errors.country)}
                fullWidth
                helperText={touched.country && errors.country}
                label="Country"
                margin="normal"
                name="country"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('country', event.target.value)}
                value={values.country}
                variant="outlined"
              >
                {' '}
                {serverErrors.country && (
                  <p className="text-danger mt-n2">{serverErrors.country}</p>
                )}
              </NewTextField>

              <NewTextField
                error={Boolean(touched.state && errors.state)}
                fullWidth
                helperText={touched.state && errors.state}
                label="State"
                margin="normal"
                name="state"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('state', event.target.value)}
                value={values.state}
                variant="outlined"
              >
                {' '}
                {serverErrors.state && <p className="text-danger mt-n2">{serverErrors.state}</p>}
              </NewTextField>

              <NewTextField
                error={Boolean(touched.city && errors.city)}
                fullWidth
                helperText={touched.city && errors.city}
                label="City"
                margin="normal"
                name="city"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('city', event.target.value)}
                value={values.city}
                variant="outlined"
              >
                {' '}
                {serverErrors.city && <p className="text-danger mt-n2">{serverErrors.city}</p>}
              </NewTextField>
            </Grid> */}
            {/* <Grid item xs={12} sm={6} className="py-0">
              <NewTextField
                fullWidth
                label="Address"
                margin="normal"
                name="address"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('address', event.target.value)}
                value={values.address}
                variant="outlined"
                multiline
                rows={5}
              />
            </Grid> */}

            {/* <Grid item xs={12} className="py-0">
              <NewTextField
                fullWidth
                label="Details"
                margin="normal"
                name="details"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('details', event.target.value)}
                value={values.details}
                variant="outlined"
                multiline
                rows={2}
                placeholder={
                  'More details about advertiser like email, alternate contact details, extra tags etc'
                }
              />
            </Grid> */}
          </Grid>

          <div className="mt-4 d-flex justify-content-end ">
            <Button
              variant="contained"
              className="btn btn-secondary d-flex"
              type="button"
              onClick={() => history.push(`/customer`)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="btn btn-primary d-flex ml-4"
              type="submit"
              disabled={isLoadingCreateAdvertiser || !values.advertiserName}
            >
              {!!advertiserId
                ? isLoadingCreateAdvertiser
                  ? 'Updating'
                  : 'Update'
                : isLoadingCreateAdvertiser
                ? 'Creating'
                : 'Create'}
            </Button>
            {isLoadingCreateAdvertiser && <OverlapLoader />}
          </div>
        </form>
      </Paper>
    </div>
  );
};

import React, { useEffect, useState, useCallback } from 'react';
import { NewSelect, Typography, Button, Loader, Grid, Paper, NewTextField } from '../../common';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { handleMsgOnForm } from '../../common/utils';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { CONTENT_URL } from '../../common/constants';
import { Container, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import OverlapLoader from '../../common/loader/OverlapLoader';

const LandingPage = ({
  campaignId,
  advertiserId,
  lineItemId,
  agencyId,
  expired = false,
  goToNextStep = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [selectedLeadform, setSelectedLeadform] = useState(null);
  const [serverErrors, setServerErrors] = useState({});
  const [leadformOptions, setLeadformOptions] = useState([]);
  const [landingFormFieldsData, setLandingFormFieldsData] = useState({});
  const [formData, setFormData] = useState({});
  const {
    data: leadFormData,
    isLoading: isLoadingLeadFormData,
    refetch: refetchLeadFormData,
  } = useQuery(
    ['LEADFORM_All_DATA', agencyId, advertiserId],
    async () => {
      const queryStringParams = {
        agencyId,
        published: true,
      };
      const response = await Services.leadFormSearch(
        advertiserId,
        queryString.stringify(queryStringParams),
      );
      return response?.data?.leadFormDTOList || [];
    },
    { enabled: !!agencyId && !!advertiserId, refetchOnWindowFocus: false, staleTime: 300000 },
  );
  const { data: lineitemLeadform, isLoading: isLoadingLineitemLeadform } = useQuery(
    ['LINEITEM_LEADFORM', lineItemId, advertiserId, campaignId],
    async () => {
      const response = await Services.getLeadformsToLineItem(
        advertiserId,
        campaignId,
        lineItemId,
        agencyId,
      );
      setLandingFormFieldsData(response.data?.leadFormDTOList[0] || {});
      return response.data?.leadFormDTOList || null;
    },
    {
      enabled: !!agencyId && !!lineItemId,
    },
  );

  const handleInputChange = (fieldName) => (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: event.target.value,
    }));
  };

  const handleDropDownChange = (selectedOption) => {
    const selectedLeadForm = leadFormData.find((form) => form.id === selectedOption.value);
    setSelectedLeadform({
      ...selectedLeadForm,
      label: selectedLeadForm.name,
      value: selectedLeadForm.id,
    });
    setLandingFormFieldsData(selectedLeadForm); // Set the selected lead form data
  };

  const { mutate: updateLeadform, isLoading: isUpdatingLeadform } = useMutation(
    async (leadformId) => {
      const payload = leadformId ? [leadformId] : [];
      const response = await Services.addLeadformsToLineItem(
        advertiserId,
        campaignId,
        lineItemId,
        agencyId,
        payload,
      );
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        enqueueSnackbar('Updated successfully.', { variant: 'success' });
        goToNextStep(lineItemId);
      },
    },
  );

  const updateLeadformCallback = useCallback(() => {
    updateLeadform(selectedLeadform?.value);
  }, [selectedLeadform, updateLeadform]);

  useEffect(() => {
    if (leadFormData) {
      const leadformOptions = leadFormData.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setLeadformOptions(leadformOptions);
    }
  }, [leadFormData]);

  useEffect(() => {
    if (lineitemLeadform && lineitemLeadform.length > 0) {
      const firstLeadForm = lineitemLeadform[0];
      setSelectedLeadform({
        ...firstLeadForm,
        label: firstLeadForm.name,
        value: firstLeadForm.id,
      });
      setLandingFormFieldsData(firstLeadForm);
    }
  }, [lineitemLeadform]);

  return (
    <>
      {isLoadingLeadFormData ? (
        <Loader />
      ) : (
        <div className="pt-3 p-4 mn-h-200 LI-creation-tab">
          <NewSelect
            info="Select lead form to attach to the campaign"
            options={leadformOptions}
            value={selectedLeadform}
            onChange={handleDropDownChange}
            placeholder={`Select Lead Form`}
            isMulti={false}
            className="py-2"
          />

          <div>
            {selectedLeadform ? (
              <div className="row pl-2">
                <Typography variant="subtitle1" className="lh-1 mb-3">
                  Selected Lead Form:
                </Typography>

                <div
                  className="landing-page align-items-center p-4"
                  style={{ backgroundColor: '#FFD580' }}
                >
                  <Grid container justify="center" alignItems="center">
                    <Grid
                      item
                      xs={4}
                      className="d-flex flex-column justify-content-end align-items-center temp"
                    >
                      <div>
                        <div className="">
                          <div
                            elevation={3}
                            className="p-3 d-flex align-items-center justify-content-center logoImg"
                          >
                            <img
                              className="rounded-sm p-2 logoImg"
                              src={`${CONTENT_URL}/${landingFormFieldsData?.logoUrl}`}
                              alt="logo"
                            />
                          </div>

                          <div className="pt-3 p-4 mt-4 ">
                            <div className="justify-content-center d-flex mb-2 ">
                              <Typography component="h6" style={{ overflowWrap: 'break-word' }}>
                                {landingFormFieldsData?.description}
                              </Typography>
                            </div>

                            <div className="d-flex justify-content-center">
                              <Typography
                                variant="h5"
                                style={{ fontWeight: 'bold', overflowWrap: 'break-word' }}
                              >
                                {landingFormFieldsData?.title}
                              </Typography>
                            </div>
                          </div>
                          <div justify="center" alignItems="center">
                            <Container>
                              <Paper className="p-2" style={{ width: '330px' }}>
                                <form>
                                  {landingFormFieldsData?.formFieldsList &&
                                    landingFormFieldsData?.formFieldsList.map((field, index) => (
                                      <div key={index}>
                                        {field.type === 'text' ||
                                        field.type === 'email' ||
                                        field.type === 'number' ? (
                                          <TextField
                                            // required
                                            className="mb-4"
                                            type={field.type}
                                            name={field.label}
                                            label={field.label}
                                            value={formData[field.label] || ''}
                                            onChange={handleInputChange(field.label)}
                                            fullWidth
                                            id="outlined-basic"
                                            variant="outlined"
                                          />
                                        ) : field.type === 'dropdown' ? (
                                          <FormControl
                                            fullWidth
                                            variant="outlined"
                                            className="mb-4"
                                          >
                                            <InputLabel>{field.label}</InputLabel>
                                            <Select
                                              label={field.label}
                                              value={formData[field.label] || ''}
                                              onChange={(e) =>
                                                handleInputChange(field.label, e.target.value)
                                              }
                                            >
                                              {field.options.map((option, optionIndex) => (
                                                <MenuItem key={optionIndex} value={option}>
                                                  {option}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        ) : null}
                                      </div>
                                    ))}
                                  <div className="mt-4 d-flex justify-content-center align-items-center">
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      className="btn btn-primary d-flex "
                                      disabled={true}
                                    >
                                      {landingFormFieldsData?.buttonName}
                                    </Button>
                                  </div>
                                </form>
                              </Paper>
                            </Container>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center  mn-h-200 ">
                <Typography variant="subtitle1" className="lh-1 mb-1 center">
                  Please Select Lead Form
                </Typography>
              </div>
            )}

            <div className="mt-4 d-flex justify-content-end ">
              <Button
                variant="contained"
                className="btn btn-secondary d-flex"
                onClick={history.goBack}
              >
                Cancel
              </Button>
              <Button
                disabled={expired}
                variant="contained"
                type="submit"
                className="btn btn-primary d-flex ml-4"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  updateLeadformCallback();
                }}
              >
                {isUpdatingLeadform ? 'Submitting' : 'Submit'}
              </Button>
              {isUpdatingLeadform && <OverlapLoader />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LandingPage;

import React, { useEffect, useState, Suspense, useCallback } from 'react';

import { Route, Router, Switch, useHistory, useLocation } from 'react-router-dom';
import {
  Login,
  Register,
  Dashboard,
  Campaign,
  CampaignClone,
  CampaignCreate,
  Advertiser,
  AdvertiserCreate,
  LineItem,
  LineItemClone,
  LineItemCreate,
  Creative,
  CreativesCreate,
  CreativesImage,
  Agency,
  AgencyCreate,
  AgencyAdminCreate,
  AgencyAccount,
  Users,
  UserCreate,
  UpdateProfile,
  UpdatePassword,
  Inventory,
  InventoryCreate,
  InventoryPlacements,
  CampaignDashboard,
  ForgotPassword,
  LeadGeneration,
  CreateLeadForm,
  LeadReport,
  ViewCreative,
  Invoice,
  AgencyDetailsTabs,
  Conversion,
  ConversionCreate,
  EmailSettings,
  CreativeClone,
  AdvertiserDashboard,
  Calculator,
} from './views';
import { Loader } from './common';
import { useDispatch } from 'react-redux';
import Layout from './hoc/layout';
import history from './history';
import getSessionData from './service-utils/session-util';
import './hoc/layout/layout.scss';
import { FlagProvider } from './libs';
import isEmpty from 'lodash/isEmpty';

const GuardedRoutesBehindFeatureFlag = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Layout>
        <Switch>
          <Route path="/dashboard" component={Dashboard} exact />
          <Route path="/update-password" component={UpdatePassword} exact />
          <Route path="/update-agency" component={AgencyDetailsTabs} exact />
          <Route path="/update-profile" component={UpdateProfile} exact />
          <Route path="/advertiser/report" component={CampaignDashboard} exact />

          <Route path="/customer" component={Advertiser} exact />
          <Route path="/customer-create" component={AdvertiserCreate} exact />
          <Route path="/customer/:advertiserId" component={AdvertiserCreate} exact />

          <Route path="/advertiser/dashboard" component={AdvertiserDashboard} exact />

          <Route path="/advertiser/campaign" component={Campaign} exact />
          <Route path="/advertiser/campaign-create" component={CampaignCreate} exact />
          <Route path="/advertiser/campaign/:campaignId" component={CampaignCreate} exact />
          <Route path="/advertiser/campaign-clone/:campaignId" component={CampaignClone} exact />

          <Route path="/advertiser/line-item" component={LineItem} exact />

          <Route path="/advertiser/line-item-create" component={LineItemCreate} exact />
          <Route path="/advertiser/line-item/:lineItemId" component={LineItemCreate} exact />
          <Route path="/advertiser/line-item-clone/:lineItemId" component={LineItemClone} exact />

          <Route path="/advertiser/lead-form" component={LeadGeneration} exact />
          <Route path="/advertiser/lead-form-create" component={CreateLeadForm} exact />
          <Route path="/advertiser/lead-form/:formId" component={CreateLeadForm} exact />
          <Route path="/advertiser/lead-report" component={LeadReport} exact />

          <Route path="/advertiser/conversion" component={Conversion} exact />
          <Route path="/advertiser/conversion-create" component={ConversionCreate} exact />
          <Route path="/advertiser/conversion/:conversionId" component={ConversionCreate} exact />

          <Route path="/advertiser/creative" component={Creative} exact />
          <Route path="/advertiser/creative-create/" component={CreativesCreate} exact />
          <Route path="/advertiser/creative-image/:creativeId" component={CreativesImage} exact />
          <Route path="/advertiser/creative/:creativeId" component={CreativesCreate} exact />
          <Route path="/advertiser/creative-clone/:creativeId" component={CreativeClone} exact />
          <Route
            path="/advertiser/creative/view-creatives/:creativeId"
            component={ViewCreative}
            exact
          />

          <Route path="/agency" component={Agency} exact />
          <Route path="/agency-create" component={AgencyCreate} exact />
          <Route path="/agency/:agencyId" component={AgencyCreate} exact />
          <Route path="/agency-admin-create/:agencyId" component={AgencyAdminCreate} exact />
          <Route path="/agency/billing/account" component={AgencyAccount} exact />
          <Route path="/agency/billing/account/invoice/:orderId" component={Invoice} exact />

          <Route path="/user" component={Users} exact />
          <Route path="/user-create" component={UserCreate} exact />
          <Route path="/user/:userId" component={UserCreate} exact />

          <Route path="/advertiser/inventory" component={Inventory} exact />
          <Route path="/advertiser/inventory-create/" component={InventoryCreate} exact />
          <Route path="/advertiser/inventory/:clusterId" component={InventoryCreate} exact />
          {/* <Route path="/advertiser/inventory-placements" component={InventoryPlacements} exact /> */}
          <Route
            path="/advertiser/inventory-placements/:clusterId"
            component={InventoryPlacements}
            exact
          />
        </Switch>
      </Layout>
    </Suspense>
  );
};

const GuardedRoutes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [loggedInUser, setLoggedInUser] = useState();

  const handleLoggedInUser = useCallback(async () => {
    const { user } = getSessionData();
    if (!isEmpty(user)) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: user });
      setLoggedInUser(user);
    }
  }, []);

  useEffect(() => {
    handleLoggedInUser();
  }, [location]);

  useEffect(() => {
    try {
      const { user, accessToken, refreshToken } = getSessionData();

      if (!user || !accessToken || !refreshToken) {
        history.push('/login');
        return;
      }

      if (user) {
        if (location.pathname === '/') {
          history.push('/dashboard');
        }
      }
    } catch (err) {
      //history.push('/login');
    }
  }, [history, location]);

  if (!loggedInUser) return '';

  return (
    <FlagProvider loggedInUser={loggedInUser}>
      <GuardedRoutesBehindFeatureFlag />
    </FlagProvider>
  );
};

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router history={history}>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/register" component={Register} exact />
          <Route path="/forgot-password" component={ForgotPassword} exact />
          <Route path="/calculator" component={Calculator} exact />
          <Route path="/terms-and-conditions" component={Register} exact />
          <Route path="/privacy-policy" component={Register} exact />
          <Route path="/" component={GuardedRoutes} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default AppRoutes;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeader } from '../../libs/redux-sdk/actions';
import history from '../../history';
import Services from '../../service-utils/services';
import getSessionData from '../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import { CONTENT_URL } from './../../common/constants';
import { Paper, Grid, Button, Typography } from '../../common';
import PageSkeleton from './pageSkeleton';
import { UploadFile } from '../../components';
import { isEmpty } from 'lodash';
import { ConformationBox } from '../../components/conformationBox';
import { useHistory } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
const FILE_TYPE = {
  banner: 'Image/png, Image/jpeg, Image/jpg, Image/gif',
  html5: 'zip',
};

export const CreativesImage = () => {
  const { creativeId } = useParams();
  const { agencyId, advertiserId } = getSessionData();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const [fileType, setFileType] = useState('banner');
  const [file, setFile] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [fileUploadDesc, setFileUploadDesc] = useState('');
  const [selectedCreative, setSelectedCreative] = useState('banner');
  const [isPublished, setIsPublished] = useState(true);
  const [bannerSize, setBannerSize] = useState({});
  const [isError, setIsError] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [conformation, setConformation] = useState();
  const [values, setValues] = useState();
  const [scale, setScale] = useState(1.0);
  const history = useHistory();
  const handleBackButtonClick = () => {
    if (creativeId) {
      // If in the subpage, navigate back to the parent page
      history.push('/advertiser/creative');
    } else {
      // If already on the parent page, go back in history
      history.goBack();
    }
  };

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: '/advertiser/creative', // Replace with your desired path
        },
      }),
    );
  }, [dispatch]);

  const { data: creativeData = {}, refetch: refetchCreative } = useQuery(
    ['CREATIVE_DATA_IMG', advertiserId, creativeId, agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.creativeGetDetails(
        advertiserId,
        creativeId,
        queryString.stringify(payload),
      );
      return response.data?.creativeDTO;
    },
    { enabled: !!creativeId && !!agencyId },
  );

  const { mutate: creativeUpdate, isLoading: isLoadingCreativeUpdate } = useMutation(
    async (payload) => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.creativeUpdate(advertiserId, creativeId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, () => {});
      },
      onSuccess: (data, payload) => {
        //uploadImage(data?.creativeDTO?.id);
        enqueueSnackbar('Creative updated successfully.', {
          variant: 'success',
        });
        history.push(`/advertiser/creative?advertiserId=${advertiserId}`);
      },
    },
  );

  const handleOnSubmit = useCallback(() => {
    const { id, advertiserId, name, bannerDTO, masterCreativeAttributes } = creativeData;

    let payload = {
      id,
      published: true,
    };
    if (!isEmpty(bannerDTO)) {
      payload = {
        ...payload,
        advertiserId,
        name,
        masterCreativeAttributes,
        bannerDTO,
      };
    } else {
      payload = {
        ...payload,
        videoDTO: {},
      };
    }
    creativeUpdate(payload);
  }, [creativeData]);

  useEffect(() => {
    const { bannerDTO = {}, published = false } = creativeData || {};
    let description = '';
    const isImageBannerAdv = !isEmpty(bannerDTO);
    setIsPublished(published);
    if (isImageBannerAdv) {
      const bannerSize = bannerDTO?.masterBannerSizeDTO?.formatName;
      bannerDTO.bannerUrl && setImageUrl(bannerDTO.bannerUrl);

      if (isImageBannerAdv && bannerDTO.adType === 2) {
        //Banner adv
        setFileType('banner');
        description = `Please use "${FILE_TYPE.banner}" format with a size of ${bannerSize} AND ensure the banner size is 200kb.`;
      } else if (isImageBannerAdv && bannerDTO.adType === 4) {
        //HTML5 adv
        setFileType('html5');
        description = `Please use "${FILE_TYPE.html5}" format with a size of ${bannerSize} AND ensure the file size is 10mb.`;
      }
      setFileUploadDesc(description);
      setSelectedCreative('banner');
      setBannerSize(bannerDTO?.masterBannerSizeDTO);
    } else {
      //TO DO - HANDLE VIDEO ADV
      setSelectedCreative('video');
    }
  }, [creativeData]);
  let height = creativeData?.bannerDTO?.masterBannerSizeDTO?.height;
  let width = creativeData?.bannerDTO?.masterBannerSizeDTO?.width;
  //Upload Image
  const uploadImage = (id) => {
    if (typeof file !== 'undefined' && file?.type) {
      let formData = new FormData();
      formData.append('file', file);

      Services.uploadFile(advertiserId, id, selectedCreative, formData, agencyId)
        .then(({ data }) => {
          //enqueueSnackbar('Banner/Video added successfully.', {variant: 'success',});
          refetchCreative();
          //history.push(`/advertiser/creative?advertiserId=${data?.creativeDTO?.advertiserId}`);
        })
        .catch((err) => {
          handleMsgOnForm(err, enqueueSnackbar, () => {});
        })
        .finally(() => {
          //console.log("finally");
        });
    }
  };

  useEffect(() => {
    if (!isError) {
      uploadImage(creativeId);
    }
  }, [creativeId, file, isError]);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      let newScale;

      if (windowWidth > 1430) {
        newScale = 0.7;
      } else if (windowWidth > 1300) {
        newScale = 0.6;
      } else if (windowWidth > 1243) {
        newScale = 0.5;
      } else if (windowWidth > 1162) {
        newScale = 0.5;
      } else {
        newScale = 0.4; // Default scale for width below 534 pixels
      }

      setScale(newScale);
    };

    // Initial scale calculation
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup: Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="col-md-10 bg-white rounded shadow m-auto mb-4 p-0">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            Upload Creative
          </Typography>
        </div>
        {imageUrl ? (
          <Grid className="row">
            <Grid item xs={6} className="py-0 mt-2" style={{ height, width }}>
              {creativeData?.bannerDTO?.adType === 4 ? (
                <iframe
                  src={`${CONTENT_URL}/${imageUrl}`}
                  title="creative"
                  scrolling="no"
                  className="rounded-sm"
                  style={{
                    height,
                    width,
                    border: '2px solid black',
                    boxSizing: 'border-box',
                    transform: width > 534 ? `scale(${scale})` : 'none',
                    display: 'block',
                    transformOrigin: 'top left',
                  }}
                />
              ) : (
                <img
                  className="mw-100 rounded-sm"
                  src={`${CONTENT_URL}/${imageUrl}`}
                  alt="banner"
                  style={{
                    border: '2px solid black', // Adjust border width and color as needed
                    padding: '10px',
                  }}
                />
              )}
            </Grid>
            <Grid item xs={6} className="py-0 mt-2">
              <div className="pt-0">
                <Button
                  variant="contained"
                  type="submit"
                  onClick={(e) => {
                    setImageUrl();
                    e.preventDefault();
                    setTimeout(() => {
                      inputFile.current.click();
                    }, 100);
                  }}
                  className="btn btn-primary d-flex mt-2"
                  disabled={isPublished}
                >
                  {creativeData?.bannerDTO?.adType === 2 ? 'Update Image' : 'Update File'}
                </Button>
                <Button
                  variant="contained"
                  className="btn btn-primary d-flex mt-2"
                  onClick={() => {
                    setIsPreviewVisible(true);
                  }}
                  disabled={creativeData?.bannerDTO?.adType === 4}
                >
                  Preview
                </Button>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} className="py-0 mt-2">
              <UploadFile
                accept={fileType === 'html5' ? 'application/zip' : FILE_TYPE[fileType]}
                desc={fileUploadDesc}
                acceptedFileTypes={['png', 'jpeg', 'jpg', 'zip']}
                getImage={(file) => setFile(file.file_format)}
                key={FILE_TYPE[selectedCreative]}
                disabled={isPublished && creativeId}
                fileDimensions={bannerSize?.formatName || '0x0'}
                setIsError={setIsError}
                inputFileRef={inputFile}
              />
            </Grid>
          </Grid>
        )}
        <div className="mt-4">
          {creativeData?.bannerDTO?.adType === 4 && (
            <p>
              {`You have selected ${bannerSize?.formatName}. If your image is cut or does not fit within the content, 
   then make sure you have selected the correct size for the ad.`}
            </p>
          )}
        </div>

        <div className="mt-4 d-flex justify-content-end ">
          <Button
            variant="contained"
            className="btn btn-secondary d-flex"
            onClick={() => history.push(`/advertiser/creative?advertiserId=${advertiserId}`)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            className="btn btn-primary d-flex ml-3"
            disabled={(isPublished && creativeId) || isError}
            onClick={() => history.push(`/advertiser/creative?advertiserId=${advertiserId}`)}
          >
            Save
          </Button>
          {creativeId && (
            <Button
              variant="contained"
              type="submit"
              className="btn btn-primary d-flex ml-3"
              disabled={(isPublished && creativeId) || isError || isEmpty(imageUrl)}
              onClick={(e) => {
                e.preventDefault();
                setConformation(true);
              }}
            >
              Update And Publish
            </Button>
          )}
        </div>
      </Paper>

      {isPreviewVisible && (
        <PageSkeleton
          bannerSize={bannerSize}
          imageUrl={`${CONTENT_URL}/${imageUrl}`}
          handleClose={() => {
            setIsPreviewVisible(false);
          }}
        />
      )}
      <ConformationBox
        isOpen={!!conformation}
        handleClose={() => setConformation()}
        handleSubmit={() => handleOnSubmit()}
        title="confirm ?"
        subtitle="Are you sure you want to Publish this creative? Once publish it cannot be rollback."
        btnCloseLabel="Close"
        btnSubmitLabel="confirm"
      />
      {isLoadingCreativeUpdate && <OverlapLoader />}
    </div>
  );
};

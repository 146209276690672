/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';
import Logo from './../../../assets/img/adzy-full.png';

const Footer = React.memo(() => {
  return (
    <>
      <footer className="footer d-flex">
        <div>
          <p className="footer-link">
            &copy;
            {new Date().getFullYear()}&nbsp;Adzylytics. All rights reserved.
          </p>
          <Link
            className="footer-link"
            to={{ pathname: 'https://www.adzylytics.com' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Home
          </Link>
          <Link
            className="footer-link"
            to={{ pathname: 'https://www.adzylytics.com/about-us/' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            About Us
          </Link>
          <Link
            className="footer-link"
            to={{ pathname: 'https://www.adzylytics.com/contact/' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us
          </Link>
          <Link
            className="footer-link"
            to={{ pathname: 'https://www.adzylytics.com/privacy-policy/' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <Link
            className="footer-link"
            to={{ pathname: 'https://www.adzylytics.com/terms-and-conditions/' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </Link>
          <Link
            className="footer-link"
            to={{ pathname: 'https://www.adzylytics.com/adzylytics-ads-policy/' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Ads Policy
          </Link>
          <Link
            className="footer-link"
            to={{ pathname: 'https://www.adzylytics.com/refund-and-cancellation/' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Refund Policy
          </Link>
          <Link
            className="footer-link"
            to={{ pathname: 'https://www.adzylytics.com/shipping-and-delivery-policy/' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Shipping & Delivery Policy
          </Link>
        </div>
      </footer>
    </>
  );
});

export default Footer;

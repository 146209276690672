import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { setHeader } from '../../libs/redux-sdk/actions';
import Services from '../../service-utils/services';
import getSessionData from './../../service-utils/session-util';
import { Table, SearchInput } from '../../components';
import { Button, Loader, Typography } from './../../common';
import { Dropdown } from 'react-bootstrap';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

export const Conversion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchText, setSearchText] = useState('');
  const queryParams = queryString.parse(location.search);
  let { agencyId } = getSessionData();

  const { data, isLoading, refetch } = useQuery(
    ['CONVERSION_DATA', pageLimit, pageNumber, agencyId, queryParams.name],
    async () => {
      const queryStringParams = {
        pageNum: pageNumber - 1,
        pageSize: pageLimit,
        agencyId,
        name: queryParams.name,
      };
      const response = await Services.conversionSearch(queryString.stringify(queryStringParams));
      return response?.data;
    },
    { enabled: !!agencyId },
  );

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Conversion
          </div>
        ),
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const parsedPageNum = Number(queryParams.pageNum);
    if (!isNaN(parsedPageNum) && parsedPageNum + 1 !== pageNumber) {
      setPageNumber(parsedPageNum + 1);
    }
    setPageLimit(Number(queryParams.pageSize) || 10);
  }, [location.search]);

  const handleApply = useCallback(() => {
    const updatedParams = {
      ...queryParams,
      name: searchText,
      pageNum: 0, // Reset to first page when applying filters
      pageSize: pageLimit,
    };
    history.replace({ search: queryString.stringify(updatedParams) });
    setPageNumber(1);
    refetch();
  }, [searchText, pageLimit, history, queryParams, refetch]);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    const updatedParams = {
      ...queryString.parse(location.search),
      pageNum: page - 1, // adjust for 0-based index
      pageSize: sizePerPage,
    };
    history.replace({ search: queryString.stringify(updatedParams) });
  };

  const manageButton = (row) => (
    <Dropdown id="dropdown-item-button" className="action-dropdown btn-action ">
      <Dropdown.Toggle variant="link">
        <MoreHorizIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu className="shadow-lg">
        <Dropdown.Item onClick={() => history.push(`/advertiser/conversion/${row.id}`)}>
          View
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const getTableHeader = [
    {
      dataField: 'name',
      text: 'Conversion Name',
      formatter: (col, row) => <a href={`/advertiser/conversion/${row.id}`}>{col}</a>,
    },
    {
      dataField: 'id',
      text: '',
      formatter: (col, row) => manageButton(row),
    },
  ];
  const handleButtonClick = () => {
    history.push('/advertiser/conversion-create');
  };
  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex">
          <SearchInput onChange={(e) => setSearchText(e.target.value)} value={searchText} />
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38 ml-3"
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
        <Button
          variant="contained"
          className="btn btn-primary d-flex btn-add"
          onClick={() => history.push(`/advertiser/conversion-create`)}
        >
          New Conversion
        </Button>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={data?.conversionsList || []}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'firstName',
              order: 'asc',
            },
          ]}
          additionalText="To get started, set up conversion tracking to count the number of conversions."
          onButtonClick={handleButtonClick}
          buttonLabel="Click here to new conversion"
        />
      )}
    </>
  );
};

import React, { useEffect } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { NewTextField, Paper, Grid, Button, Typography } from '../../common';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import getSessionData from '../../service-utils/session-util';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
export const UpdateProfile = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  let { userId, agencyId, roles } = getSessionData();
  let history = useHistory();
  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
        },
      }),
    );
  }, [dispatch]);

  const { data: userData = {} } = useQuery(
    ['USER_DATA', userId],
    async () => {
      const response = await Services.getUserProfileDetails(userId, agencyId);
      return response?.data?.userProfileDTO;
    },
    { enabled: !!userId && !!agencyId },
  );

  //console.log('userData',userData)

  const { mutate: updateProfile, isLoading } = useMutation(
    async (values) => {
      const { name, email } = values;
      const payload = {
        id: userId,
        name: name,
        email: email,
      };
      const query = queryString.stringify({ agencyId });
      const response = await Services.userProfileUpdate(userId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        let errorMsg = err?.response.data.reasonDesc || 'Error in updating profile.';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      },
      onSuccess: (data) => {
        enqueueSnackbar('Profile updated successfully.', { variant: 'success' });
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Name is required.'),
    }),
    onSubmit: () => updateProfile(values),
  });

  useEffect(() => {
    if (!isEmpty(userData)) {
      setFieldValue('name', userData.name);
      setFieldValue('email', userData.email);
    }
  }, [userData]);
  // const isOwner = roles.includes('OWNER');
  return (
    <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            Update Profile
          </Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} className="py-0">
              <NewTextField
                required
                info="Enter new name to update"
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('name', event.target.value)}
                value={values.name}
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12} className="py-0">
              <NewTextField
                required
                info="Enter new email id to update"
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email id"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('email', event.target.value)}
                value={values.email}
                variant="outlined"
                disabled
                type="text"
              />
            </Grid>
          </Grid>
          <div className="mt-4 d-flex justify-content-end ">
            <Button variant="contained" class="btn btn-secondary d-flex" onClick={history.goBack}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" class="btn btn-primary d-flex ml-4">
              Update
            </Button>
          </div>
          {isLoading && <OverlapLoader />}
        </form>
      </Paper>
      {/* {isOwner && <AgencyUpdate />} */}
    </div>
  );
};
